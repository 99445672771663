import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useCustomerContext } from 'contexts/Customer';
import { useDarkstoreContext } from 'contexts/Darkstore';
import api from 'utils/api';
import { joinPaths } from 'utils/helpers';

const setFavorite = productId => api.get(`products/favourite/${productId}`);

const useSetFavorite = ({ productId, queryKey }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { push } = useHistory();

  const queryClient = useQueryClient();

  const { customer } = useCustomerContext();

  const { darkstoreId } = useDarkstoreContext();

  const { mutate } = useMutation(() => setFavorite(productId), {
    onSuccess: () => {
      if (['catalog/shopping-lists', 'catalog/favorites'].includes(queryKey)) {
        queryClient.invalidateQueries(queryKey);
      } else {
        queryClient.invalidateQueries([queryKey, { darkstoreId }]);
      }
      queryClient.invalidateQueries(`products/${productId}`);
    },
  });

  return {
    setFavorite: () => {
      if (customer) {
        mutate();
      } else {
        push(joinPaths('/', language, 'favorites'));
      }
    },
  };
};

export default useSetFavorite;
