import React from 'react';
import {
  Button,
  LoadingIndicator,
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from 'components/shared';
import useDeletePaymentCard from './useDeletePaymentCard';

const DeletePaymentCardModal = ({ paymentCardToken, onClose }) => {
  const { deletePaymentCard, isDeleting } = useDeletePaymentCard({ onSuccess: onClose });

  return (
    <Modal isOpened onClose={onClose}>
      <ModalTitle onClose={onClose}>Удаление карты</ModalTitle>
      {isDeleting ? (
        <LoadingIndicator />
      ) : (
        <>
          <ModalContent>Вы действительно хотите удалить карту?</ModalContent>
          <ModalActions>
            <Button color="default" variant="text" onClick={onClose}>
              Отмена
            </Button>
            <Button color="error" onClick={() => deletePaymentCard(paymentCardToken)}>
              Да
            </Button>
          </ModalActions>
        </>
      )}
    </Modal>
  );
};

export default DeletePaymentCardModal;
