import React from 'react';
import { SvgIcon } from '../shared';

const Favorite = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12 23.010l-1.74-1.584c-6.18-5.604-10.26-9.3-10.26-13.836 0-3.696 2.904-6.6 6.6-6.6 2.088 0 4.092 0.972 5.4 2.508 1.308-1.536 3.312-2.508 5.4-2.508 3.696 0 6.6 2.904 6.6 6.6 0 4.536-4.080 8.232-10.26 13.848l-1.74 1.572z"></path>
    </SvgIcon>
  );
};

export default Favorite;
