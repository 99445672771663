import React from 'react';
import { SvgIcon } from '../shared';

const ChevronRight = props => {
  return (
    <SvgIcon {...props}>
      <path d="M7.41 0l-2.82 2.82 9.16 9.18-9.16 9.18 2.82 2.82 12-12-12-12z"></path>
    </SvgIcon>
  );
};

export default ChevronRight;
