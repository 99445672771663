import React, { useContext, useState } from 'react';
import { AccordionContext } from '../Accordion';
import styles from './AccordionDetails.module.scss';

const getStyle = (height, isExpanded) => {
  if (height) {
    if (isExpanded) {
      return {
        height: height,
      };
    }
    return { height: 0 };
  }
  return {};
};

const AccordionDetails = ({ children }) => {
  const { isExpanded } = useContext(AccordionContext);
  const [height, setHeight] = useState(null);
  const style = getStyle(height, isExpanded);

  return (
    <div
      ref={ref => ref && ref.clientHeight > 0 && setHeight(ref.clientHeight)}
      style={style}
      className={styles.root}
    >
      {children}
    </div>
  );
};

export default AccordionDetails;
