import { useQuery } from 'react-query';
import { useDarkstoreContext } from 'contexts/Darkstore';
import api from 'utils/api';
import { mapProducts } from 'utils/mappers';

const useAvailability = ({ cartProducts, onSuccess }) => {
  const { darkstoreId } = useDarkstoreContext();

  useQuery({
    queryKey: ['cart', { darkstoreId }],
    queryFn: async () => {
      const ids = cartProducts.map(cartProduct => cartProduct.id);

      const products = await api.get(`api/productsList/${darkstoreId}/${JSON.stringify(ids)}`);

      const result = mapProducts(products).reduce(
        (acc, product) => {
          if (product.qty === 0) {
            acc.sold.push(product);
          } else {
            const cartQty = cartProducts.find(cartProduct => cartProduct.id === product.id).qty;
            if (product.qty < cartQty) {
              acc.changed.push(product);
              acc.products.push({ ...product, maxQty: product.qty });
            } else {
              acc.products.push({ ...product, maxQty: product.qty, qty: cartQty });
            }
          }
          return acc;
        },
        {
          products: [],
          changed: [],
          sold: [],
        },
      );

      return result;
    },
    enabled: cartProducts.length > 0,
    onSuccess,
  });
};

export default useAvailability;
