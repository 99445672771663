import { useQuery } from 'react-query';
import api from 'utils/api';

const useAddresses = ({ query }) => {
  const { data } = useQuery({
    queryKey: ['addresses', { query }],
    queryFn: () => api.get('v2/addresses', { q: query }),
    keepPreviousData: true,
  });

  return { addresses: data ?? [] };
};

export default useAddresses;
