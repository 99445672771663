import { isValidPhoneNumber } from 'libphonenumber-js';
import * as yup from 'yup';

yup.addMethod(yup.string, 'phone', function () {
  return this.test({
    name: 'phone-test',
    test: value => {
      if (!value) {
        return true;
      }
      return isValidPhoneNumber(value.startsWith('+') ? value : `+${value}`);
    },
    message: { key: 'string.matches' },
  });
});

// prettier-ignore
const URL_REGEXP = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)$/;

yup.addMethod(yup.string, 'url', function () {
  return this.test({
    name: 'url-test',
    test: value => URL_REGEXP.test(value),
    message: { key: 'string.matches' },
  });
});

const BIRTHDAY_REGEXP = /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19\d{2}|200[0-7])$/;

yup.addMethod(yup.string, 'birthday', function () {
  return this.test({
    name: 'birthday-test',
    test: value => {
      if (!value) {
        return true;
      }
      return BIRTHDAY_REGEXP.test(value);
    },
    message: { key: 'custom.birthday.matches' },
  });
});

yup.setLocale({
  mixed: {
    required: { key: 'mixed.required' },
    notType: ({ type }) => {
      switch (type) {
        case 'number':
          return { key: 'mixed.notType.number' };
        case 'string':
          return { key: 'mixed.notType.string' };
        default:
          return { key: 'mixed.notType.default' };
      }
    },
  },
  number: {
    default: { key: 'number.default' },
    integer: { key: 'number.integer' },
    positive: { key: 'number.positive' },
    min: ({ min }) => ({ key: 'number.min', value: min }),
    max: ({ max }) => ({ key: 'number.max', value: max }),
  },
  string: {
    default: { key: 'string.default' },
    matches: { key: 'string.matches' },
    email: { key: 'string.email' },
  },
});

export default yup;
