import React, { useEffect, useState } from 'react';
import { Notifications, NotificationsOutline } from 'components/icons';
import { useCustomerContext } from 'contexts/Customer';
import useSetWait from './useSetWait';

const WaitButton = ({ isWait, productId, queryKey }) => {
  const { customer } = useCustomerContext();

  const [isFilled, setIsFilled] = useState(isWait);

  const { setWait } = useSetWait({ productId, isWait, queryKey });

  useEffect(() => setIsFilled(isWait), [isWait]);

  if (!customer) {
    return null;
  }

  if (isFilled) {
    return (
      <Notifications
        color="primary"
        size={20}
        onClick={e => {
          e.stopPropagation();
          setIsFilled(!isFilled);
          setWait();
        }}
      />
    );
  }

  return (
    <NotificationsOutline
      size={20}
      onClick={e => {
        e.stopPropagation();
        setIsFilled(!isFilled);
        setWait();
      }}
    />
  );
};

export default WaitButton;
