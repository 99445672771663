import { useQuery } from 'react-query';
import { useDarkstoreContext } from 'contexts/Darkstore';
import api from 'utils/api';

const getPolygons = async () => {
  const data = await api.get('polygons');
  return data.map(d => ({
    ...d,
    active: !!d.active,
    points: JSON.parse(d.points).map(p => ({ lat: p[0], lng: p[1] })),
  }));
};

const usePolygons = ({ onSuccess }) => {
  const { darkstore } = useDarkstoreContext();

  const { isLoading, data = [] } = useQuery('polygons', getPolygons, {
    onSuccess: polygons => {
      const meta = [];
      polygons
        .filter(p => p.country === darkstore.country)
        .forEach(p => {
          if (!meta.find(m => m.deliveryTime === p.defaultDeliveryTime)) {
            meta.push({ deliveryTime: p.defaultDeliveryTime, color: p.color });
          }
        });
      meta.sort((a, b) => (a.deliveryTime > b.deliveryTime ? 1 : -1));
      onSuccess({
        polygons,
        meta,
      });
    },
  });

  return { isLoading, polygons: data };
};

export default usePolygons;
