import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Menu as MenuIcon, Logout as LogoutIcon } from 'components/icons';
import { Button, Popper } from 'components/shared';
import { useCustomerContext } from 'contexts/Customer';
import { useDarkstoreContext } from 'contexts/Darkstore';
import noAvatar from 'img/noAvatar.png';
import phone from 'img/phone.svg';
import fbIcon from 'img/social/fb.svg';
import instIcon from 'img/social/inst.svg';
import okIcon from 'img/social/ok.svg';
import vkIcon from 'img/social/vk.svg';
import ytbIcon from 'img/social/ytb.svg';
import { joinPaths } from 'utils/helpers';
import styles from './HeaderMobileMenu.module.scss';

const HeaderMobileMenu = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { push } = useHistory();

  const { customer, logout } = useCustomerContext();
  const {
    darkstore: { name, schedule, tel, email, country, facebook, youtube, vk, ok, instagram },
  } = useDarkstoreContext();

  const [referenceElement, setReferenceElement] = useState(null);

  return (
    <>
      <MenuIcon
        className={styles.icon}
        color={!!referenceElement ? 'primary' : 'default'}
        size={24}
        onClick={e => setReferenceElement(!!referenceElement ? null : e.currentTarget)}
      />
      <Popper
        className={styles.popper}
        referenceElement={referenceElement}
        isOpened={!!referenceElement}
        withArrow
        offset={[0, 16]}
        onClickOutside={() => setReferenceElement(null)}
      >
        <div className={styles.links}>
          <Link to={joinPaths('/', language)} onClick={() => setReferenceElement(null)}>
            {t('main:header.links.0')}
          </Link>
        </div>

        <div className={styles.divider} />

        {customer ? (
          <div
            className={clsx(styles.user, styles.userLogged)}
            onClick={() => {
              setReferenceElement(null);
              push(joinPaths('/', language, '/account/info'));
            }}
          >
            <img
              className={styles.userAvatar}
              src={customer.photo || noAvatar}
              alt="Avatar"
              width={46}
              height={46}
            />
            <div className={styles.userInfo}>
              <span className={styles.userName}>{`${customer.name} ${customer.surname}`}</span>
              <span className={styles.userPhone}>+{customer.phone}</span>
            </div>
            <LogoutIcon size={18} onClick={logout} />
          </div>
        ) : (
          <div className={styles.user}>
            <Link to={joinPaths('/', language, 'login')} onClick={() => setReferenceElement(null)}>
              <Button>{t('main:header.login')}</Button>
            </Link>
          </div>
        )}

        <div className={styles.divider} />

        <span className={styles.darkstoreName}>{name}</span>
        <span className={styles.darkstoreWorktime}>
          {t('main:header.weekdays')}: {schedule[1].start} - {schedule[1].finish},{' '}
          {t('main:header.weekends')}: {schedule[6].start} - {schedule[6].finish}
        </span>
        <div className={styles.darkstoreContacts}>
          {tel.map(({ tel, icon }, index) => (
            <a href={`tel:${tel}`} key={`darkstore-tel-${index}`}>
              {icon ? (
                <img src={icon} alt="OperatorIcon" loading="lazy" width={30} height={30} />
              ) : (
                <img src={phone} alt="PhoneIcon" loading="lazy" width={24} height={24} />
              )}
              {tel}
            </a>
          ))}
          <a href={`mailto:${email}`}>{email}</a>
        </div>

        <div className={styles.divider} />

        <div className={styles.socials}>
          {facebook && (
            <a href={facebook} target="_blank" rel="noreferrer nofollow">
              <img src={fbIcon} alt="FacebookIcon" width={26} height={26} />
            </a>
          )}
          {youtube && (
            <a href={youtube} target="_blank" rel="noreferrer nofollow">
              <img src={ytbIcon} alt="YoutubeIcon" width={26} height={26} />
            </a>
          )}
          {country !== 'ua' && (
            <>
              {vk && (
                <a href={vk} target="_blank" rel="noreferrer nofollow">
                  <img src={vkIcon} alt="VkIcon" width={26} height={26} />
                </a>
              )}

              {ok && (
                <a href={ok} target="_blank" rel="noreferrer nofollow">
                  <img src={okIcon} alt="OkIcon" width={26} height={26} />
                </a>
              )}
            </>
          )}
          {instagram && (
            <a href={instagram} target="_blank" rel="noreferrer nofollow">
              <img src={instIcon} alt="InstagramIcon" width={26} height={26} />
            </a>
          )}
        </div>
      </Popper>
    </>
  );
};

export default HeaderMobileMenu;
