import React, { useCallback, useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const BrowserHistoryContext = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOCATION_CHANGE': {
      const newState = { ...state };

      if (state.currentPathname) {
        newState.canGoBack = true;
      }

      newState.previousPathname = state.currentPathname;
      newState.currentPathname = action.payload;

      return newState;
    }
    default:
      return state;
  }
};

const initialState = {
  canGoBack: false,
  previousPathname: '',
  currentPathname: '',
};

const BrowserHistoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { push, replace } = useHistory();

  const location = useLocation();

  useEffect(() => {
    dispatch({ type: 'LOCATION_CHANGE', payload: location.pathname });
  }, [location.pathname]);

  const { canGoBack, previousPathname } = state;

  const goBackOrPush = useCallback(
    url => {
      if (canGoBack) {
        replace(previousPathname);
      } else {
        push(url);
      }
    },
    [previousPathname, canGoBack, replace, push],
  );

  const goBackOrReplace = useCallback(
    url => {
      if (canGoBack) {
        replace(previousPathname);
      } else {
        replace(url);
      }
    },
    [previousPathname, canGoBack, replace],
  );

  return (
    <BrowserHistoryContext.Provider value={{ ...state, goBackOrPush, goBackOrReplace }}>
      {children}
    </BrowserHistoryContext.Provider>
  );
};

export { BrowserHistoryContext, BrowserHistoryProvider };
