import React from 'react';
import { SvgIcon } from '../shared';

const Star = props => {
  return (
    <SvgIcon {...props}>
      <path d="M11.474 0.942c0.166-0.509 0.886-0.509 1.052 0l2.454 7.552c0.074 0.228 0.286 0.382 0.526 0.382h7.94c0.536 0 0.758 0.685 0.325 1l-6.424 4.667c-0.194 0.141-0.275 0.39-0.201 0.618l2.454 7.552c0.166 0.51-0.417 0.933-0.851 0.618l-6.424-4.667c-0.194-0.141-0.456-0.141-0.65 0l-6.424 4.667c-0.433 0.315-1.016-0.109-0.851-0.618l2.454-7.552c0.074-0.228-0.007-0.477-0.201-0.618l-6.424-4.667c-0.433-0.315-0.211-1 0.325-1h7.94c0.24 0 0.452-0.154 0.526-0.382l2.454-7.552z"></path>
    </SvgIcon>
  );
};

export default Star;
