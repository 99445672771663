import React from 'react';
import clsx from 'clsx';
import { capitalize } from 'utils/helpers';
import styles from './TextField.module.scss';

const Input = (
  {
    children,
    className,
    classes = {},
    variant = 'outlined',
    multiline = false,
    id,
    name,
    type,
    label,
    placeholder,
    defaultValue,
    value,
    message,
    error,
    onChange,
    ...other
  },
  ref,
) => {
  const inputProps = {
    className: clsx(styles.input, classes.input),
    ref,
    id,
    name,
    type,
    placeholder,
    defaultValue,
    value,
    onChange,
    ...other,
  };

  return (
    <div
      className={clsx(
        styles.root,
        styles[`variant${capitalize(variant)}`],
        className,
        classes.root,
      )}
    >
      {variant === 'default' && label && <label htmlFor={id}>{label}</label>}

      <div
        className={clsx(
          styles.inputContainer,
          styles[`variant${capitalize(variant)}`],
          classes.inputContainer,
        )}
      >
        {children
          ? React.cloneElement(children, inputProps)
          : React.createElement(multiline ? 'textarea' : 'input', inputProps)}

        {variant === 'outlined' && (
          <div className={clsx(styles.spacer, classes.spacer)}>
            <div className={styles.spacerStart} />

            <div className={styles.spacerCenter}>
              {label && <label htmlFor={id}>{label}</label>}
            </div>

            <div className={styles.spacerEnd} />
          </div>
        )}
      </div>

      {message && !error && <span className={styles.message}>{message}</span>}

      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default React.forwardRef(Input);
