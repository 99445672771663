import React, { forwardRef } from 'react';
import clsx from 'clsx';
import styles from './ModalContent.module.scss';

const ModalContent = forwardRef(({ children = null, className = null, classes = {} }, ref) => {
  return (
    <div ref={ref} className={clsx(styles.root, className, classes.root)}>
      {children}
    </div>
  );
});
export default ModalContent;
