import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Logout as LogoutIcon, User as UserIcon } from 'components/icons';
import { Menu, MenuItem } from 'components/shared';
import { useCustomerContext } from 'contexts/Customer';
import noAvatar from 'img/noAvatar.png';
import { joinPaths } from 'utils/helpers';
import styles from './HeaderDesktopMenu.module.scss';

const HeaderDesktopMenu = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { push } = useHistory();
  const { customer, logout } = useCustomerContext();
  const [referenceElement, setReferenceElement] = useState(null);

  return (
    <>
      <img
        className={styles.avatar}
        src={(customer && customer.photo) || noAvatar}
        alt="Avatar"
        onClick={e => setReferenceElement(!!referenceElement ? null : e.currentTarget)}
      />
      <Menu
        referenceElement={referenceElement}
        isOpened={!!referenceElement}
        onClickOutside={() => setReferenceElement(null)}
      >
        <MenuItem
          icon={<UserIcon />}
          text={t('main:header.avatarMenu.0')}
          onClick={() => {
            push(joinPaths('/', language, 'account/info'));
            setReferenceElement(null);
          }}
        />
        <MenuItem
          icon={<LogoutIcon />}
          text={t('main:header.avatarMenu.1')}
          onClick={() => {
            logout();
            setReferenceElement(null);
          }}
        />
      </Menu>
    </>
  );
};

export default HeaderDesktopMenu;
