import React, { useReducer } from 'react';
import { useQueryClient } from 'react-query';
import { useOnMount } from 'hooks';
import { LoadingIndicator } from 'components/shared';
import { onAuthStateChanged, signOut } from 'lib/firebase';
import useCustomer from './useCustomer';
import useRefreshToken from './useRefreshToken';
import useSetPaymentCard from './useSetPaymentCard';

const CustomerContext = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZED':
      return { ...state, isInitializing: false };
    case 'LOGGED_IN':
      return { ...state, isAuthenticated: true, isFirebaseAuth: action.payload };
    case 'LOGGED_OUT':
      return { ...state, isAuthenticated: false, isFirebaseAuth: false, paymentCard: null };
    default:
      return state;
  }
};

const initialState = {
  isInitializing: true,
  isAuthenticated: false,
  isFirebaseAuth: localStorage.getItem('isFirebaseAuth') === 'true',
};

const CustomerProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const [state, dispatch] = useReducer(reducer, initialState);

  const { isInitializing, isAuthenticated, isFirebaseAuth } = state;

  const setIsInitialized = () => dispatch({ type: 'INITIALIZED' });

  const login = ({ token, refresh, isFirebaseAuth }) => {
    localStorage.setItem('token', token);
    localStorage.setItem('refresh', refresh);
    localStorage.setItem('isFirebaseAuth', isFirebaseAuth);
    dispatch({ type: 'LOGGED_IN', payload: isFirebaseAuth });
  };

  const logout = () => {
    signOut();
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('isFirebaseAuth');
    localStorage.removeItem('isLoyaltyModalViewed1');
    queryClient.removeQueries('account');
    dispatch({ type: 'LOGGED_OUT' });
  };

  const { isLoading, customer } = useCustomer({ enabled: isAuthenticated });

  const { setPaymentCard } = useSetPaymentCard();

  const { refreshToken } = useRefreshToken({
    onSuccess: ({ token, refreshToken: refresh }) => {
      login({ token, refresh, isFirebaseAuth: false });
      setIsInitialized();
    },
    onError: () => {
      logout();
      setIsInitialized();
    },
  });

  useOnMount(() => {
    onAuthStateChanged(async user => {
      if (user) {
        const { phoneNumber } = user;
        sessionStorage.setItem('phoneNumber', phoneNumber);
        const token = await user.getIdToken();
        login({ token, refresh: null, isFirebaseAuth: true });
        setIsInitialized();
      } else if (isFirebaseAuth) {
        logout();
        setIsInitialized();
      }
    });

    if (!isFirebaseAuth) {
      const token = localStorage.getItem('token');
      if (token) {
        const refresh = localStorage.getItem('refresh');
        refreshToken(refresh);
      } else {
        setIsInitialized();
      }
    }
  });

  if (isInitializing) {
    return <LoadingIndicator />;
  }

  return (
    <CustomerContext.Provider
      value={{
        ...state,
        isLoading,
        customer,
        login,
        logout,
        setPaymentCard,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export { CustomerContext, CustomerProvider };
