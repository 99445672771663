import { useMutation, useQueryClient } from 'react-query';
import api from 'utils/api';
import message from 'utils/message';

const useEditLoyalty = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation({
    mutationFn: variables => {
      return api.put('customers', {
        surname:
          !variables.loyalty.isParticipation ||
          (!variables.loyalty.isResearch &&
            !variables.loyalty.isMessages &&
            !variables.loyalty.isGames)
            ? ''
            : variables.customer.surname,
        patronymic:
          !variables.loyalty.isParticipation ||
          (!variables.loyalty.isResearch &&
            !variables.loyalty.isMessages &&
            !variables.loyalty.isGames)
            ? ''
            : variables.customer.patronymic,
        gender:
          !variables.loyalty.isParticipation || !variables.loyalty.isResearch
            ? ''
            : variables.customer.gender,
        email: !variables.loyalty.isMessages ? '' : variables.customer.email,
        birthday:
          !variables.loyalty.isParticipation || !variables.loyalty.isResearch
            ? ''
            : variables.customer.birthday,
        ...variables,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData('account', account => ({ ...account, ...variables }));
      window.location.reload(true);
      onSuccess();
    },
    onError: error => message(error.message, { type: 'error', withIcon: true }),
  });

  return { isEditing: isLoading, edit: mutate };
};

export default useEditLoyalty;
