import React, { useState } from 'react';
import { useDebouncedValue } from 'hooks';
import { Popper, TextField } from 'components/shared';
import useAddresses from './useAddresses';
import useCheckAddress from './useCheckAddress';
import styles from './AddressAutocomplete.module.scss';

const AddressAutocomplete = ({ placeholder, onChange }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebouncedValue(query);

  const { addresses } = useAddresses({ query: debouncedQuery });
  const { checkAddress } = useCheckAddress({
    onSuccess: (darkstore, address) => {
      const { name, lat, lon } = address;
      const [street, building, city = 'Минск', country = 'Беларусь'] = name
        .split(',')
        .map(v => v.trim());
      onChange({
        darkstoreId: darkstore.id,
        zoneId: darkstore.zoneId,
        street,
        building,
        city,
        country,
        lat,
        lon,
      });
    },
  });

  return (
    <div className={styles.root}>
      <TextField
        placeholder={placeholder}
        value={query}
        onChange={e => setQuery(e.target.value)}
        onFocus={e => setReferenceElement(e.currentTarget)}
      />
      <Popper
        className={styles.popper}
        referenceElement={referenceElement}
        isOpened={!!referenceElement && addresses.length > 0}
        withArrow={false}
        autoWidth={true}
        offset={[0, 0]}
        placement="bottom-start"
        onClickOutside={() => setReferenceElement(null)}
      >
        <ul className={styles.options}>
          {addresses.map((address, index) => (
            <li
              key={index}
              className={styles.option}
              onClick={() => {
                setQuery(address.name);
                checkAddress(address);
                setReferenceElement(null);
              }}
            >
              {address.name}
            </li>
          ))}
        </ul>
      </Popper>
    </div>
  );
};

export default AddressAutocomplete;
