import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import { useMediaQuery } from 'hooks';
import { Close as CloseIcon } from 'components/icons';
import { Button } from 'components/shared';
import mobileAppsLinks from 'constants/mobileAppsLinks';
import { isIOS, isMobile } from 'utils/helpers';
import styles from './MobileAppBanner.module.scss';

const isInitialVisible =
  isMobile() &&
  localStorage.getItem('isNewbieGuideViewed') &&
  !localStorage.getItem('isMobileAppDownloaded') &&
  !sessionStorage.getItem('isMobileAppBannerClosed');

const MobileAppBanner = () => {
  const { t } = useTranslation('main', { keyPrefix: 'mobileAppBanner' });

  const [isVisible, setIsVisible] = useState(isInitialVisible);

  const isMaxWidth640 = useMediaQuery('screen and (max-width: 640px)');

  useEffect(() => {
    if (isVisible) {
      document.documentElement.style.setProperty('--header-top', '56px');
      document.documentElement.style.setProperty('--main-top', '120px');
    } else {
      sessionStorage.setItem('isMobileAppBannerClosed', true);
      document.documentElement.style.setProperty('--header-top', '0px');
      document.documentElement.style.setProperty('--main-top', isMaxWidth640 ? '64px' : '75px');
    }
  }, [isVisible, isMaxWidth640]);

  if (isVisible) {
    return (
      <div className={styles.root}>
        <CloseIcon className={styles.icon} onClick={() => setIsVisible(false)} />
        <div className={styles.title}>
          <span>{t('subtitle')}</span>
          <span>{t('title', { siteName: config.SITE_NAME })}</span>
        </div>
        <Button
          size="small"
          onClick={() => {
            setIsVisible(false);
            localStorage.setItem('isMobileAppDownloaded', true);
            if (isIOS()) {
              window.open(mobileAppsLinks.ios, '_blank');
            } else {
              window.open(mobileAppsLinks.android, '_blank');
            }
          }}
        >
          {t('button')}
        </Button>
      </div>
    );
  }

  return null;
};

export default MobileAppBanner;
