import React from 'react';
import { SvgIcon } from '../shared';

const Cart = props => {
  return (
    <SvgIcon {...props}>
      <path d="M7.733 15.515h0.001c0.001 0 0.002-0 0.003-0h12.748c0.314 0 0.59-0.208 0.676-0.51l2.813-9.844c0.061-0.212 0.018-0.44-0.115-0.616s-0.341-0.28-0.561-0.28h-17.186l-0.503-2.262c-0.072-0.322-0.357-0.551-0.686-0.551h-4.219c-0.388 0-0.703 0.315-0.703 0.703s0.315 0.703 0.703 0.703h3.655c0.089 0.401 2.405 10.824 2.539 11.424-0.747 0.325-1.271 1.070-1.271 1.935 0 1.163 0.946 2.109 2.109 2.109h12.75c0.388 0 0.703-0.315 0.703-0.703s-0.315-0.703-0.703-0.703h-12.75c-0.388 0-0.703-0.315-0.703-0.703s0.314-0.702 0.701-0.703zM22.365 5.671l-2.411 8.438h-11.656l-1.875-8.438h15.941z"></path>
      <path d="M7.029 20.438c0 1.163 0.946 2.109 2.109 2.109s2.109-0.946 2.109-2.109c0-1.163-0.946-2.109-2.109-2.109s-2.109 0.946-2.109 2.109zM9.139 19.735c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703-0.703-0.315-0.703-0.703c0-0.388 0.315-0.703 0.703-0.703z"></path>
      <path d="M16.971 20.438c0 1.163 0.946 2.109 2.109 2.109s2.109-0.946 2.109-2.109c0-1.163-0.946-2.109-2.109-2.109s-2.109 0.946-2.109 2.109zM19.081 19.735c0.388 0 0.703 0.315 0.703 0.703s-0.315 0.703-0.703 0.703-0.703-0.315-0.703-0.703c0-0.388 0.315-0.703 0.703-0.703z"></path>
    </SvgIcon>
  );
};

export default Cart;
