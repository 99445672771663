import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
  TextField,
  MaskTextField,
  LoadingIndicator,
} from 'components/shared';
import useAddPaymentCard from './useAddPaymentCard';
import styles from './AddPaymentCardModal.module.scss';

const VALIDATION_SCHEMA = yup.object().shape({
  number: yup
    .string()
    .matches(/^\d{16}$/)
    .required(),
  holder: yup
    .string()
    .matches(/^([A-Za-z]+\s?){1,3}$/)
    .required(),
  exp: yup
    .string()
    .matches(/^(0[1-9]|1[0-2])(2[1-9]|[3-9][0-9])$/)
    .required(),
});

const AddPaymentCardModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { isAdding, addPaymentCard } = useAddPaymentCard({
    onSuccess: onClose,
  });

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      number: '',
      holder: '',
      exp: '',
    },
    validationSchema: VALIDATION_SCHEMA,
    validateOnChange: false,
    onSubmit: addPaymentCard,
  });

  return (
    <Modal isOpened width={300}>
      <ModalTitle onClose={onClose}>Добавление карты</ModalTitle>
      <ModalContent>
        {isAdding ? (
          <LoadingIndicator />
        ) : (
          <form className={styles.form} onSubmit={handleSubmit}>
            <MaskTextField
              stripValue
              variant="default"
              name="number"
              label="Номер карты"
              placeholder="XXXX XXXX XXXX XXXX"
              alwaysShowMask={false}
              mask="9999 9999 9999 9999"
              value={values.number}
              error={errors.number && t(`validation:${errors.number.key}`)}
              onChange={handleChange}
            />
            <TextField
              variant="default"
              name="holder"
              label="Имя держателя карты"
              placeholder="IVAN IVANOV"
              value={values.holder}
              error={errors.holder && t(`validation:${errors.holder.key}`)}
              onChange={handleChange}
            />
            <MaskTextField
              stripValue
              variant="default"
              name="exp"
              label="Месяц/год"
              placeholder="ММ/ГГ"
              alwaysShowMask={false}
              mask="99/99"
              value={values.exp}
              error={errors.exp && t(`validation:${errors.exp.key}`)}
              onChange={handleChange}
            />
            <p>
              Bazar не хранит данные карты.
              <br /> Данные карты хранятся на стороне банка-эквайера.
            </p>
            <Button type="submit" size="large">
              Сохранить
            </Button>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AddPaymentCardModal;
