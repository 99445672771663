import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

const LocalizedRouter = ({ children }) => {
  return (
    <BrowserRouter>
      <Route path={['/:lang([a-z]{2})', '/']}>{children}</Route>
    </BrowserRouter>
  );
};

export default LocalizedRouter;
