import React from 'react';
import { SvgIcon } from '../shared';

const Courier = props => {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m17.47 8.304 2.7 10.866 1.129-.334-2.792-10.718-1.036.186Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m5.915 17.737 13.967-.028-1.348 1.225H5.727l.188-1.197Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M6.13 20.707c1.083 0 1.96-.873 1.96-1.95 0-1.076-.877-1.949-1.96-1.949s-1.961.873-1.961 1.95c0 1.076.878 1.95 1.96 1.95Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m8.751 13.808.025-.193-.017-.115-.196-.068-.034-.005c-.595-.178-1.257-.413-1.864-.589-.108-.031-.138-.057-.247-.081-.369-.104-.706-.218-1.07-.336-.176-.057-.352-.108-.53-.167-.17-.056-.352-.104-.519-.168-.29-.1-.598-.188-.896-.283-.076-.024-.816-.235-.856-.294l-.323.898c-.046.218.2.252.362.3.174.051.339.115.514.165.322.09.7.21 1.018.32.562.197 1.429.45 2.044.649.346.11.695.204 1.03.325.325.118.687.219 1.016.325.392.126.343-.218.543-.683ZM12.148 14.874l.288.03-.094-.889-.194.859ZM6.394 9.177l.025.003a.132.132 0 0 0 .018-.007c-.06-.128-.108-.13-.25-.106-.004.012-.162.172-.003.286.104.074.245.014.21-.176ZM5.941 10.418c-.008-.155-.17-.075-.127-.228.14-.046.091.084.244.085-.052-.158-.172-.065-.14-.219.12-.018.116.094.236.102-.003-.078.02-.03-.045-.088a.936.936 0 0 0-.092-.067c-.143-.07-.152.034-.19.088-.042.06-.138.09-.068.201.028.044.13.121.182.126ZM6.751 9.405c.006-.103-.143-.048-.129-.21l.074-.01c.08.015.084.097.17.092-.022-.123-.16-.08-.141-.204.113-.073.123.082.252.08-.016-.064-.097-.125-.162-.15-.133-.053-.133.042-.177.096-.062.078-.193.088.025.269l.055.037.011.002h.011a.04.04 0 0 0 .011-.002ZM5.775 10.581c-.083-.034-.05.045-.18.003-.034-.073-.041-.169.086-.133.095.026.056.109.17.076-.038-.104-.281-.246-.336-.07-.043.14.09.23.191.204.059-.014.067-.031.07-.08ZM6.147 9.552c.014.08.237.259.331.192.065-.085.053-.206-.045-.198l.001.025.002.014.004.014c.014.067.024-.013.013.09-.1.015-.12-.01-.167-.085.052-.071.075-.041.084-.147-.095.018-.052.022-.104.069-.084.076-.023.01-.119.026ZM7.166 8.857c-.12.029-.025.067-.169.073-.052-.042-.094-.067-.036-.146.071.09-.036.054.07.093.02-.05.017-.075-.009-.154-.06-.02-.11-.024-.14.062-.071.212.282.296.284.072Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.19 7.873c-1.446-.02-3.157.633-4.068 1.622.018.333.434 1.279.56 1.616.571-.15.822-.432 1.574-.415.09.002.184.012.277.03.903.133 2.101-.29 2.566-.671.415-.34.747-.999.674-2.027a21.094 21.094 0 0 0-1.583-.155Zm-.72 4.02c-.113.663-.353 1.394-.623 1.68-.224.282-.52.493-.966.48L5.698 15.83c.369.25 1.347.64 1.976.627 1.213-.024 2.663-.343 3.485-1.083.247-.223.24-.188.574-.074 1.14.386 2.027.944 2.537 1.931l1.975-.236c-.07-1.471-.032-2.296-.532-3.069-1.07-1.542-2.769-1.928-3.023-2.314 1.496-.058 2.664-.085 3.81-1.398.169-.193.599-.746.67-1-.194-.306-.914-1.184-1.169-1.383-.4.19-.55.532-1.454.647-1.803.14-1.925-.092-3.062-.326.098 2.533-1.257 3.12-3.015 3.741Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m8.016 15.013.197.068c.601.086.937.51 1.12.034.11-.291.077-.282.284-.47.069-.063.136-.128.205-.194l.967-1.003.712-.849-.07-.433-.89.997c-.026.027-.047.044-.077.074-.056.056-.088.083-.142.14l-.768.711a3.629 3.629 0 0 1-.152.135c-.09.075-.236.223-.329.272l-.323-.1c-.052-.017-.015-.007-.057-.021a.203.203 0 0 0-.116-.016c-.044-.04-.238-.114-.294-.104l-.002-.011c-.296-.073-.447-.497-.755-.593-.151-.047-.292-.094-.443-.142-.156-.05-.302-.086-.453-.144-.068-.008-.023 0-.057-.017-.362-.114-.687-.208-1.08-.339-.17-.057-.357-.12-.53-.167-.14-.04-.398-.15-.53-.16-.12-.068-.356-.114-.502-.165-.15-.051-.34-.114-.496-.163-.32-.1-.685-.201-.998-.312-.02.023-.119.21-.241.511-.066.163-.188.51-.196.538.04.06.78.27.856.294.299.095.607.184.896.283.167.064.35.112.52.168.177.059.353.11.53.167.363.118.7.232 1.07.336.109.024.139.05.247.08.606.177 1.269.411 1.863.59l.034.005Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m2.22 12.482-.07.195c.248-.307.392.589.685.54.018-.051.007-.014.022-.056a.199.199 0 0 0 .017-.115c.041-.043.387-.075.377-.13l.011-.002c.076-.294.197-.606.297-.912.048-.15.097-.29.147-.438.051-.155.09-.3.149-.45.008-.067 0-.022.018-.056.118-.359.215-.681.35-1.07.06-.17.124-.354.174-.526.041-.139.155-.394.166-.525.07-.12.119-.354.17-.498.054-.148.118-.337.17-.491.104-.318.208-.68.323-.99-.036-.03-.932-.417-1.014-.44-.06.04-.28.774-.304.849-.098.296-.19.602-.293.888-.066.166-.116.347-.174.516-.06.175-.114.35-.173.524-.122.361-.24.695-.348 1.061-.025.108-.051.138-.084.245-.183.601-.425 1.258-.61 1.847l-.005.034ZM15.348 8.344c-.345.368-.904.642-.482 1.059-.015.165-.02.367-.043.53-.093.694-.525.705-1.079 1.098-.037.026-.11.266-.15.289-.033.02-.886.258-.904.291.612-.096 1.11.034 1.8-.163a4.516 4.516 0 0 0 1.028-.424 4.95 4.95 0 0 0 .565-.373l.126-.103.126-.11c.173-.128.489-.5.631-.694l.216-.322c.07-.12.2-.382.239-.508.066-.21.093-.285.102-.505a.449.449 0 0 0-.005-.097c-.038-.293-.169-.439-.419-.59-.08-.05-.2-.098-.3-.121-.537-.121-1.068.397-1.45.743Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M12.29 7.817a2.31 2.31 0 0 1-2.542-2.048 2.304 2.304 0 0 1 2.06-2.529 2.31 2.31 0 0 1 2.542 2.048 2.304 2.304 0 0 1-2.06 2.529Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.72 10.89c.412-.293 1.014-.453.683-.944.047-.159.092-.356.146-.512.23-.661.654-.588 1.275-.865.042-.019.161-.24.205-.254.036-.013.92-.08.943-.11-.619-.025-1.082-.25-1.796-.192a4.53 4.53 0 0 0-1.092.216 4.983 4.983 0 0 0-.628.256 5.211 5.211 0 0 0-.144.075l-.145.085c-.195.09-.578.393-.756.556l-.275.274a3.51 3.51 0 0 0-.335.451c-.106.193-.147.261-.2.475a.457.457 0 0 0-.014.096c-.021.295.078.463.294.661.07.064.176.135.27.177.503.224 1.126-.18 1.57-.445Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M20.039 20.727c1.083 0 1.96-.872 1.96-1.95 0-1.076-.877-1.949-1.96-1.949s-1.96.873-1.96 1.95.877 1.95 1.96 1.95Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m10.287 8.092.199.06c-.396.016.242.665.028.869-.052-.016-.016-.003-.059-.016a.202.202 0 0 1-.103-.055c-.059.008-.29.264-.33.223l-.008.008c-.283-.114-.606-.204-.912-.306-.15-.05-.292-.095-.442-.144-.156-.052-.295-.107-.452-.149-.06-.034-.018-.013-.056-.02-.36-.119-.679-.233-1.073-.357-.172-.054-.36-.113-.528-.175-.137-.05-.41-.112-.523-.18-.138-.016-.356-.117-.504-.162a20.78 20.78 0 0 1-.497-.158c-.32-.106-.673-.238-.992-.331-.003-.046.218-.993.249-1.071.068-.025.792.238.866.262.298.098.6.207.892.296.174.046.35.114.52.168.178.056.35.118.527.175.364.117.704.222 1.064.355.102.044.142.04.248.078.595.213 1.269.411 1.855.615l.03.015Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};

export default Courier;
