import React from 'react';
import { CookiesPolicy } from './CookiesPolicy';
import { Footer } from './Footer';
import { Header } from './Header';
import { MobileAppBanner } from './MobileAppBanner';
import { MobileMenu } from './MobileMenu';
import { NewbieGuide } from './NewbieGuide';

const Main = ({ children }) => {
  return (
    <>
      <MobileAppBanner />
      <Header />
      <main id="main" className="Main">
        {children}
      </main>
      <Footer />
      <MobileMenu />
      <CookiesPolicy />
      <NewbieGuide />
    </>
  );
};

export default Main;
