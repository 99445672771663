import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cart } from 'components';
import { Cart as CartIcon } from 'components/icons';
import { Popper } from 'components/shared';
import { useCartContext } from 'contexts/Cart';
import { useDarkstoreContext } from 'contexts/Darkstore';
import styles from './HeaderCart.module.scss';

const HeaderCart = () => {
  const { t } = useTranslation('main', { keyPrefix: 'header.cart' });
  const {
    darkstore: { currency },
  } = useDarkstoreContext();
  const { sum, products } = useCartContext();
  const [referenceElement, setReferenceElement] = useState(null);

  return (
    <>
      <div
        className={styles.trigger}
        onClick={e => setReferenceElement(!!referenceElement ? null : e.currentTarget)}
      >
        <CartIcon color="white" size={24} />
        <span className={styles.triggerDivider} />
        <div className={styles.triggerInfo}>
          <span className={styles.triggerSum}>
            {sum}
            {currency}
          </span>
          <span className={styles.triggerProducts}>
            {products.length} {t('pcs')}
          </span>
        </div>
        {products.some(product => product.readOnly) > 0 && (
          <span className={styles.triggerPackage}>{t('package')}</span>
        )}
      </div>
      <Popper
        className={styles.popper}
        referenceElement={referenceElement}
        isOpened={!!referenceElement}
        withArrow
        onClickOutside={() => setReferenceElement(null)}
      >
        <Cart onCheckout={() => setReferenceElement(null)} />
      </Popper>
    </>
  );
};

export default HeaderCart;
