import { useMutation, useQueryClient } from 'react-query';
import { useCustomerContext } from 'contexts/Customer';
import api from 'utils/api';

const useAddAddress = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const { customer } = useCustomerContext();

  const { mutate, isLoading } = useMutation({
    mutationFn: async variables => {
      await Promise.all(
        customer.addresses.map(address =>
          api.put('/customers/addresses', { ...address, isActive: 0 }),
        ),
      );
      return api.post('customers/addresses', { ...variables, isActive: 1 });
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData('account', account => {
        const addresses = account.addresses.map(address => ({ ...address, isActive: 0 }));
        addresses.push({ addressId: data.addressId, ...variables, isActive: 1 });
        return { ...account, addresses };
      });
      queryClient.invalidateQueries('account');
      onSuccess();
    },
  });

  return { isAdding: isLoading, add: mutate };
};

export default useAddAddress;
