import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useMediaQuery } from 'hooks';
import { Button, Modal, ModalActions, ModalContent, ModalTitle } from 'components/shared';
import { useAddressContext } from 'contexts/Address';
import { useCustomerContext } from 'contexts/Customer';
import { useDarkstoreContext } from 'contexts/Darkstore';
import { closeModal, openModal } from 'utils/modals';
import { AddressAutocomplete } from './AddressAutocomplete';
import { AddressForm } from './AddressForm';
import { Map } from './Map';
import { WaitingModal } from './WaitingModal';
import useAddAddress from './useAddAddress';
import validateForm from './validateForm';
import styles from './AddressModal.module.scss';

const INITIAL_VALUES = {
  darkstoreId: null,
  zoneId: null,
  currency: null,
  street: '',
  building: '',
  corps: '',
  flat: '',
  entrance: '',
  floor: '',
  intercom: '',
  lat: 0,
  lon: 0,
};

const AddressModal = ({ onClose }) => {
  const { t } = useTranslation();

  const { mapCenter, mapZoom, setDarkstoreParams } = useDarkstoreContext();
  const { setAddress } = useAddressContext();
  const { customer } = useCustomerContext();

  const [meta, setMeta] = useState([]);

  const { add } = useAddAddress({ onSuccess: onClose });

  const { values, errors, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: INITIAL_VALUES,
    validateOnChange: false,
    validate: validateForm,
    onSubmit: values => {
      const { darkstoreId, zoneId, ...address } = values;
      if (customer && customer.addresses) {
        add(address);
      } else {
        setDarkstoreParams({ darkstoreId, zoneId });
        setAddress(address);
        onClose();
      }
    },
  });

  const isFullScreen = useMediaQuery('screen and (max-width: 420px)');

  const handleClose = () => {
    sessionStorage.setItem('isAddressAsked', true);
    onClose();
  };

  return (
    <Modal isOpened isFullScreen={isFullScreen} width={780} onClose={handleClose}>
      <ModalTitle
        classes={{ root: styles.header, closeIcon: styles.closeIcon }}
        isTextContent={false}
        onClose={handleClose}
      >
        <span className={styles.headerTitle}>{t('address:modalTitle')}</span>
        <span className={styles.headerSubtitle}>{t('address:modalSubtitle')}</span>
      </ModalTitle>
      <ModalContent>
        {values.zoneId ? (
          <AddressForm
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        ) : (
          <AddressAutocomplete
            placeholder={t('address:autocompletePlaceholder')}
            onChange={address => setValues({ ...values, ...address })}
          />
        )}
        <Map
          darkstoreId={values.darkstoreId}
          zoneId={values.zoneId}
          currency={values.currency}
          center={mapCenter}
          zoom={mapZoom}
          position={{ lat: values.lat, lng: values.lon }}
          onLoaded={({ meta }) => setMeta(meta)}
          onWait={() => {
            const { modalId } = openModal(
              <WaitingModal
                street={values.street}
                building={values.building}
                onClose={() => closeModal(modalId)}
              />,
            );
          }}
        />
      </ModalContent>
      <ModalActions className={styles.footer}>
        <ul className={styles.meta}>
          {meta.map(m => (
            <li key={m.deliveryTime} className={styles.metaItem}>
              <span
                className={styles.metaItemCircle}
                style={{
                  border: `2px solid rgba(${m.color})`,
                  backgroundColor: `rgba(${m.color})`,
                }}
              />
              <p className={styles.metaItemText}>
                - {t('address:deliveryZone.0')}
                <span>{t('address:deliveryZone.1', { deliveryTime: m.deliveryTime })}</span>
              </p>
            </li>
          ))}
        </ul>
        {values.zoneId && (
          <Button type="submit" size="small" onClick={handleSubmit}>
            {t('address:saveAddress')}
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};

export default AddressModal;
