import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useMediaQuery } from 'hooks';
import cart from 'img/message/cart.svg';
import error from 'img/message/error.svg';
import success from 'img/message/success.svg';

const message = (text = '', options = {}) => {
  toast(
    <>
      {options.type === 'success' && options.withIcon && <img src={success} alt="success" />}
      {options.type === 'error' && options.withIcon && <img src={error} alt="error" />}
      {options.type === 'default' && options.withIcon && <img src={cart} alt="cart" />}
      {text}
    </>,
    options,
  );
};

export const MessagesOutlet = () => {
  const isMaxWidth640 = useMediaQuery('screen and (max-width: 640px)');

  return (
    <ToastContainer
      limit={isMaxWidth640 ? 1 : null}
      autoClose={isMaxWidth640 ? 1500 : 3000}
      position={isMaxWidth640 ? 'top-center' : 'bottom-left'}
      hideProgressBar
      closeButton={false}
      pauseOnHover={false}
    />
  );
};

export default message;
