import { useMutation } from 'react-query';
import api from 'utils/api';

const useWait = ({ onSuccess }) => {
  const { mutate } = useMutation(
    ({ address, phone }) => api.post('darkstores', { address, phone }),
    { onSuccess },
  );
  return { wait: mutate };
};

export default useWait;
