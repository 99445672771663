import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import config from 'config';
import api from 'utils/api';
import message from 'utils/message';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCXOxvINNUkJnZ5tMKFs8nUJd7ZiyRfAtg',
  authDomain: 'bazar-dc6c1.firebaseapp.com',
  databaseURL: 'https://bazar-dc6c1.firebaseio.com',
  projectId: 'bazar-dc6c1',
  storageBucket: 'bazar-dc6c1.appspot.com',
  messagingSenderId: '960775920712',
  appId: '1:960775920712:web:55353cd901698717904c7d',
  measurementId: 'G-LL49RZ8B88',
};

firebase.initializeApp(FIREBASE_CONFIG);

const auth = firebase.auth();
auth.useDeviceLanguage();

const createVerifier = elementId => {
  return new firebase.auth.RecaptchaVerifier(elementId, {
    size: 'invisible',
  });
};

const signInWithPhoneNumber = (phoneNumber, applicationVerifier) => {
  return auth.signInWithPhoneNumber(phoneNumber, applicationVerifier);
};

const onAuthStateChanged = callback => auth.onAuthStateChanged(callback);

const signOut = () => auth.signOut();

// fix app crash on http hosts
let messaging;
try {
  messaging = firebase.messaging();
} catch (error) {
  console.error('FIREBASE_INIT_ERROR: ', error);
}

const subscribeToNotifications = async queryClient => {
  try {
    if (!messaging) {
      return;
    }

    if (messaging.vapidKey) {
      return;
    }

    const token = await messaging.getToken({
      vapidKey:
        'BFZ5ySysEGzofuk9Vssg8q800nVfHv15vt1DheiY3lP4_I3R5Y5O0eYUlT1cjWb2jR0UeOKprxO1z4eGCTcnRUg',
    });

    if (config.NODE_ENV !== 'production') {
      console.log(`DEV_LOG: FCM token ${token}`);
    }

    await api.post('customers/pushTokens', { token });

    messaging.onMessage(payload => {
      if (payload.notification) {
        if (payload.notification.title !== payload.notification.body) {
          message(
            <>
              <b>{payload.notification.title}</b>
              <br />
              {payload.notification.body}
            </>,
          );
        } else {
          message(payload.notification.body);
        }

        if (payload.notification.data) {
          if (payload.notification.data.key === 'addBonuses') {
            queryClient.invalidateQueries('account');
            queryClient.invalidateQueries('account/orders');
          }

          if (payload.notification.data.key === 'completeOrder') {
            queryClient.invalidateQueries('account/orders');
          }

          if (payload.notification.data.qty) {
            queryClient.invalidateQueries('account');
          }
        }
      }
    });
  } catch (error) {
    console.error('Subscribe to notification error', error);
  }
};

export {
  createVerifier,
  signInWithPhoneNumber,
  onAuthStateChanged,
  signOut,
  subscribeToNotifications,
};
