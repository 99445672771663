// scroll polyfill for Sarafi
import { polyfill } from 'seamless-scroll-polyfill';
import { isSafari } from 'utils/helpers';

if (isSafari()) {
  polyfill();
}

// import IntersectionObserver polyfill if it not supported
(async () => {
  if ('IntersectionObserver' in window === false) {
    await import('intersection-observer');
  }
})();
