import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const languageDetector = new LanguageDetector();
const domainLanguageDetector = {
  name: 'domain',
  lookup() {
    const { hostname } = window.location;
    const end = hostname.split('.').pop();
    const language = end === 'pl' ? 'pl' : 'ru';
    console.log('Language by domain detection:', { hostname, end, language });
    return language;
  },
};
languageDetector.addDetector(domainLanguageDetector);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path', 'domain', 'localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: 'language',
      lookupCookie: 'language',
    },
    supportedLngs: ['ru', 'uk', 'pl'],
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    ns: [
      'about',
      'account',
      'address',
      'catalog',
      'courier',
      'main',
      'meta',
      'order',
      'product',
      'shared',
      'statuses',
      'validation',
    ],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
