const {
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_FILESTORAGE_URL,
  REACT_LOCATION_API_KEY,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_SERVER,
} = process.env;

const config = {
  NODE_ENV: NODE_ENV || 'development',
  API_URL: REACT_APP_API_URL || 'https://api.bazar-store.by',
  FILESTORAGE_URL: REACT_APP_FILESTORAGE_URL || 'https://bazar-store.by',
  LOCATION_API_KEY: REACT_LOCATION_API_KEY || '5385054f75e2d7859df7785f4a448dc81b2d45f4',
  GOOGLE_API_KEY: REACT_APP_GOOGLE_API_KEY || 'AIzaSyD8uEijT8Aryue-HNrGdgQxQfnTrVlEGvE',
  SERVER: REACT_APP_SERVER || 'test',
  BUILD_TYPE: window.SERVER_DATA?.buildType || 'sosedi',
  SITE_NAME: window.SERVER_DATA?.siteName || 'Sosedi Dostavka',
};

export default config;
