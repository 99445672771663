import React from 'react';
import { SvgIcon } from '../shared';

const ArrowUp = props => {
  return (
    <SvgIcon {...props}>
      <path d="M0 12l2.115 2.115 8.385-8.37v18.255h3v-18.255l8.37 8.385 2.13-2.13-12-12-12 12z"></path>
    </SvgIcon>
  );
};

export default ArrowUp;
