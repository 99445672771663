import React, { useState } from 'react';
import AccordionContext from './AccordionContext';

const Accordion = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggle = () => setIsExpanded(!isExpanded);

  return (
    <AccordionContext.Provider value={{ isExpanded, toggle }}>{children}</AccordionContext.Provider>
  );
};

export default Accordion;
