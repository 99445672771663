import { useMutation } from 'react-query';
import api from 'utils/api';

const refreshToken = refresh => api.post('customers/refreshToken', { refresh });

const useRefreshToken = ({ onSuccess, onError }) => {
  const { mutate } = useMutation(refreshToken, { onSuccess, onError });
  return { refreshToken: mutate };
};

export default useRefreshToken;
