import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AddressModal } from 'components';
import { useCanDisplayGlobalModal } from 'hooks';
import { Button, LocalizedImage, Modal } from 'components/shared';
import slide1 from 'img/newbie/slide1.jpg';
import slide1PL from 'img/newbie/slide1PL.png';
import slide1UK from 'img/newbie/slide1UK.png';
import slide2 from 'img/newbie/slide2.jpg';
import slide2PL from 'img/newbie/slide2PL.png';
import slide2UK from 'img/newbie/slide2UK.png';
import slide3 from 'img/newbie/slide3.jpg';
import slide3PL from 'img/newbie/slide3PL.png';
import slide3UK from 'img/newbie/slide3UK.png';
import slide4 from 'img/newbie/slide4.jpg';
import slide4PL from 'img/newbie/slide4PL.png';
import slide4UK from 'img/newbie/slide4UK.png';
import { closeModal, openModal } from 'utils/modals';
import styles from './NewbieGuide.module.scss';

const NewbieGuide = () => {
  const { t } = useTranslation();
  const canDisplayGlobalModals = useCanDisplayGlobalModal();

  const [isOpened, setIsOpened] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const open = () => setIsOpened(true);

  const close = () => {
    setIsOpened(false);
    localStorage.setItem('isNewbieGuideViewed', true);
    const { modalId } = openModal(<AddressModal onClose={() => closeModal(modalId)} />);
  };

  useEffect(() => {
    const isNewbieGuideViewed = localStorage.getItem('isNewbieGuideViewed');
    if (canDisplayGlobalModals && !isNewbieGuideViewed) {
      open();
    }
  }, [canDisplayGlobalModals]);

  return (
    <Modal isOpened={isOpened} className={styles.root} width={360}>
      <div className={styles.header}>
        <span className={styles.skipButton} onClick={close}>
          {t('shared:buttons.skip')}
        </span>
      </div>
      <Swiper
        className={styles.slider}
        slidesPerView="auto"
        modules={[Pagination]}
        pagination={{
          el: '#newbieGuidePagination',
        }}
        onSwiper={setSwiper}
        onSlideChange={({ activeIndex }) => setCurrentSlide(activeIndex)}
      >
        <SwiperSlide>
          <LocalizedImage
            className={styles.slide}
            src={{
              ru: slide1,
              uk: slide1UK,
              pl: slide1PL,
            }}
            alt="slide1"
            width={360}
            height={570}
          />
        </SwiperSlide>
        <SwiperSlide>
          <LocalizedImage
            className={styles.slide}
            src={{
              ru: slide2,
              uk: slide2UK,
              pl: slide2PL,
            }}
            alt="slide2"
            width={360}
            height={570}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide>
          <LocalizedImage
            className={styles.slide}
            src={{
              ru: slide3,
              uk: slide3UK,
              pl: slide3PL,
            }}
            alt="slide3"
            width={360}
            height={570}
            loading="lazy"
          />
        </SwiperSlide>
        <SwiperSlide>
          <LocalizedImage
            className={styles.slide}
            src={{
              ru: slide4,
              uk: slide4UK,
              pl: slide4PL,
            }}
            alt="slide4"
            width={360}
            height={570}
            loading="lazy"
          />
        </SwiperSlide>
        <div id="newbieGuidePagination" className="swiper-pagination"></div>
      </Swiper>
      <div className={styles.footer}>
        {currentSlide === 3 ? (
          <Button className={styles.button} onClick={close}>
            {t('main:newbieGuide.checkAddress')}
          </Button>
        ) : (
          <Button className={styles.button} onClick={() => swiper.slideNext()}>
            {t('shared:buttons.next')}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default NewbieGuide;
