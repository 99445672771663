import clsx from 'clsx';
import './button.scss';

const Button = ({
  children,
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  className,
  ...props
}) => (
  <button
    className={clsx(
      'b-button',
      `b-button--variant--${variant}`,
      `b-button--color--${color}`,
      `b-button--size--${size}`,
      className,
    )}
    {...props}
  >
    {children}
  </button>
);

export default Button;
