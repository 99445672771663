import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';
import config from 'config';
import { LocalizedSwitch, PrivateRoute, ErrorBoundary } from './components';
import { useDarkstoreContext } from './contexts/Darkstore';
import {
  Account,
  Catalog,
  Checkout,
  LegalInformation,
  Login,
  Main,
  MobileApp,
  NotFound,
  PaymentResult,
  Product,
  Registration,
  TechnicalBreak,
} from './features';
import { useScrollToTop } from './hooks';

const App = () => {
  useScrollToTop();

  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const { darkstore } = useDarkstoreContext();

  return (
    <ErrorBoundary>
      <Helmet>
        <title>
          {t('meta:index.title', { siteName: config.SITE_NAME, cityName: darkstore.city })}
        </title>
        <meta
          name="title"
          content={t('meta:index.title', { siteName: config.SITE_NAME, cityName: darkstore.city })}
        />
        <meta
          name="description"
          content={t('meta:index.description', {
            siteName: config.SITE_NAME,
            cityName: darkstore.city,
          })}
        />
      </Helmet>
      <Main>
        <LocalizedSwitch>
          <Route path={`${path}/mobile-app`} component={MobileApp} />
          <Route path={`${path}/login`} component={Login} />
          <Route
            path={[`${path}/legal`, `${path}/rules`, `${path}/payment-rules`]}
            component={LegalInformation}
          />
          <Route path={`${path}/products/:productSlug`} component={Product} />
          <Route path={`${path}/registration`} component={Registration} />
          <Route path={`${path}/technical-break`} component={TechnicalBreak} />
          <Route path={`${path}/payment-result`} component={PaymentResult} />
          <Redirect from={`${path}/payment-success`} to={`${path}/payment-result?status=success`} />
          <Redirect from={`${path}/payment-fail`} to={`${path}/payment-result?status=error`} />
          <PrivateRoute path={`${path}/account`} component={Account} />
          <PrivateRoute path={`${path}/checkout`} component={Checkout} />
          <Route path={`${path}/404`} component={NotFound} />
          <Route path={path} component={Catalog} />
        </LocalizedSwitch>
      </Main>
    </ErrorBoundary>
  );
};

export default App;
