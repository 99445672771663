import React, { useEffect, useState } from 'react';
import { Favorite, FavoriteOutline } from 'components/icons';
import useSetFavorite from './useSetFavorite';

const FavoriteButton = ({ isFavorite, productId, queryKey }) => {
  const [isFilled, setIsFilled] = useState(isFavorite);

  const { setFavorite } = useSetFavorite({ productId, queryKey });

  useEffect(() => setIsFilled(isFavorite), [isFavorite]);

  if (isFilled) {
    return (
      <Favorite
        color="primary"
        size={20}
        onClick={e => {
          e.stopPropagation();
          setIsFilled(!isFilled);
          setFavorite();
        }}
      />
    );
  }

  return (
    <FavoriteOutline
      color="primary"
      size={20}
      onClick={e => {
        e.stopPropagation();
        setIsFilled(!isFilled);
        setFavorite();
      }}
    />
  );
};

export default FavoriteButton;
