import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ModalsContextProvider } from 'contexts/ModalsContext';
import App from './App';
import { LocalizedRouter } from './components';
import { LoadingIndicator } from './components/shared';
import { AddressProvider } from './contexts/Address';
import { BrowserHistoryProvider } from './contexts/BrowserHistory';
import { CartProvider } from './contexts/Cart';
import { CustomerProvider } from './contexts/Customer';
import { DarkstoreProvider } from './contexts/Darkstore';
import './lib/i18n';
import './lib/polyfill';
import './lib/yup';
import queryClient from './queryClient';
import reportWebVitals from './reportWebVitals';
import './setupCssVariables';
import { MessagesOutlet } from './utils/message';
import './styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingIndicator />}>
      <LocalizedRouter>
        <BrowserHistoryProvider>
          <QueryClientProvider client={queryClient}>
            <DarkstoreProvider>
              <AddressProvider>
                <CustomerProvider>
                  <CartProvider>
                    <ModalsContextProvider>
                      <App />
                    </ModalsContextProvider>
                  </CartProvider>
                </CustomerProvider>
              </AddressProvider>
            </DarkstoreProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </BrowserHistoryProvider>
      </LocalizedRouter>
      <MessagesOutlet />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
