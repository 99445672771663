import React from 'react';
import { SvgIcon } from '../shared';

const User = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12 0c-3.489 0-6.328 2.839-6.328 6.328s2.839 6.328 6.328 6.328c3.489 0 6.328-2.839 6.328-6.328s-2.839-6.328-6.328-6.328z"></path>
      <path d="M19.873 16.79c-1.732-1.759-4.029-2.728-6.467-2.728h-2.813c-2.438 0-4.735 0.969-6.467 2.728-1.724 1.75-2.673 4.061-2.673 6.507 0 0.388 0.315 0.703 0.703 0.703h19.688c0.388 0 0.703-0.315 0.703-0.703 0-2.445-0.949-4.756-2.673-6.507z"></path>
    </SvgIcon>
  );
};

export default User;
