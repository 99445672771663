import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useMediaQuery } from 'hooks';
import {
  Close as CloseIcon,
  Market as MarketIcon,
  Courier as CourierIcon,
  Umbrella as UmbrellaIcon,
} from 'components/icons';
import { Popper } from 'components/shared';
import { useDarkstoreContext } from 'contexts/Darkstore';
import demandMini from 'img/darkstore/demandMini.png';
import weatherMini from 'img/darkstore/weatherMini.png';
import styles from './HeaderDelivery.module.scss';

const HeaderDelivery = ({ className }) => {
  const { t } = useTranslation();

  const { darkstore } = useDarkstoreContext();

  const isMaxWidth768 = useMediaQuery('screen and (max-width: 768px)');
  const [referenceElement, setReferenceElement] = useState(null);

  const [isOpened, setIsOpened] = useState(false);
  useEffect(() => setIsOpened([3, 2].includes(darkstore.status)), [darkstore.status]);
  const toggle = () => [3, 2].includes(darkstore.status) && setIsOpened(!isOpened);

  return (
    <>
      <div ref={setReferenceElement} className={clsx(styles.trigger, className)} onClick={toggle}>
        {[-1, 0].includes(darkstore.status) && <CourierIcon className={styles.icon} />}
        {darkstore.status === 2 && <MarketIcon className={styles.icon} />}
        {darkstore.status === 3 && <UmbrellaIcon className={styles.icon} />}
        {t('main:header.deliveryTime', { count: darkstore.delivery })}
      </div>
      <Popper
        className={styles.popper}
        referenceElement={referenceElement}
        isOpened={isOpened && !isMaxWidth768}
        withArrow
        onClickOutside={toggle}
      >
        <>
          {darkstore.status === 2 && (
            <>
              <div className={styles.popperHeader}>
                <img src={demandMini} alt="DemandMini" width={36} height={24} />
                <span className={styles.popperTitle}>
                  {t('statuses:darkstore.increasedDemandTitle')}
                </span>
                <CloseIcon onClick={toggle} />
              </div>
              <p className={styles.popperText}>
                {t('statuses:darkstore.increasedDemandText.0')}
                <span>
                  {' '}
                  {darkstore.delivery} {t('statuses:darkstore.increasedDemandText.1')}{' '}
                </span>
                {t('statuses:darkstore.increasedDemandText.2')}
              </p>
            </>
          )}
          {darkstore.status === 3 && (
            <>
              <div className={styles.popperHeader}>
                <img src={weatherMini} alt="WeatherMini" width={35} height={27} />
                <span className={styles.popperTitle}>
                  {t('statuses:darkstore.badWeatherTitle')}
                </span>
                <CloseIcon onClick={toggle} />
              </div>
              <p className={styles.popperText}>
                {t('statuses:darkstore.badWeatherText.0')}
                <span>
                  {' '}
                  {darkstore.delivery} {t('statuses:darkstore.badWeatherText.1')}{' '}
                </span>
                {t('statuses:darkstore.badWeatherText.2')}
              </p>
            </>
          )}
        </>
      </Popper>
    </>
  );
};

export default HeaderDelivery;
