import React from 'react';
import ReactInputMask from 'react-input-mask';
import { TextField } from '../TextField';

const MaskTextField = ({ stripValue, stripEmptyValue, onChange, ...other }) => {
  return (
    <ReactInputMask
      alwaysShowMask
      onChange={e => {
        if (stripValue) {
          e.target.value = e.target.value.replaceAll(/[^0-9]+/g, '');
        }
        if (stripEmptyValue) {
          const stripped = e.target.value.replaceAll(/[^0-9]+/g, '');
          if (stripped.length === 0) {
            e.target.value = '';
          }
        }
        if (onChange) {
          onChange(e);
        }
      }}
      {...other}
    >
      <TextField />
    </ReactInputMask>
  );
};

export default MaskTextField;
