import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { DarkstoreSleepModal } from 'components';
import { useCanDisplayGlobalModal } from 'hooks';
import { LoadingIndicator } from 'components/shared';
import { joinPaths } from 'utils/helpers';
import { closeModal, openModal } from 'utils/modals';
import constants from './constants';
import useDarkstore from './useDarkstore';

const DarkstoreContext = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'DARKSTORE_PARAMS_CHANGED':
      return { ...state, darkstoreId: action.payload.darkstoreId, zoneId: action.payload.zoneId };
    case 'DARKSTORE_STATUS_CHANGED':
      return { ...state, darkstoreStatus: action.payload };
    case 'MODAL_OPENED':
      return { ...state, isModalOpened: true };
    case 'MODAL_CLOSED':
      return { ...state, isModalOpened: false };
    default:
      return state;
  }
};

const getCountryByDomain = () => {
  const { hostname } = window.location;
  const end = hostname.split('.').pop();
  const country = end === 'pl' ? 'pl' : 'by';
  console.log('Country by domain detection:', { hostname, end, country });
  return country;
};

const makeInitialState = () => {
  const country = getCountryByDomain();

  const defaultCountryDarkstoreId = country ? constants[country].defaultDarkstoreId : 2;

  let darkstoreId = localStorage.getItem('darkstoreId') ?? defaultCountryDarkstoreId ?? 2;
  let zoneId = localStorage.getItem('zoneId') ?? 1;

  // fix error with empty darkstore
  if (darkstoreId === 'undefined' || darkstoreId === 'null') {
    darkstoreId = defaultCountryDarkstoreId;
    zoneId = 1;
  }

  // fix error with darkstore 100
  if (darkstoreId === '100') {
    darkstoreId = 1;
    zoneId = 1;
  }

  localStorage.setItem('darkstoreId', darkstoreId);
  Cookies.set('darkstoreId', darkstoreId);
  localStorage.setItem('zoneId', zoneId);
  Cookies.set('zoneId', zoneId);

  return {
    darkstoreId: Number(darkstoreId),
    zoneId: Number(zoneId),
    darkstoreStatus: 0,
    isModalOpened: false,
  };
};

const initialState = makeInitialState();

const DarkstoreProvider = ({ children }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { replace } = useHistory();
  const { pathname } = useLocation();

  const canDisplayGlobalModals = useCanDisplayGlobalModal();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { darkstoreId, zoneId, darkstoreStatus } = state;

  const { isLoading, darkstore } = useDarkstore({
    darkstoreId,
    zoneId,
  });

  useEffect(() => {
    if (darkstore && darkstore.status !== darkstoreStatus) {
      dispatch({ type: 'DARKSTORE_STATUS_CHANGED', payload: darkstore.status });
      if (canDisplayGlobalModals && darkstore.status === -1) {
        setTimeout(() => {
          const { modalId } = openModal(
            <DarkstoreSleepModal
              startWork={darkstore.startWork}
              finishWork={darkstore.finishWork}
              onClose={() => closeModal(modalId)}
            />,
          );
        }, 100);
      }
    }
  }, [canDisplayGlobalModals, darkstore, darkstoreStatus]);

  useEffect(() => {
    if ([1, 4].includes(darkstoreStatus)) {
      replace(joinPaths('/', language, 'technical-break'));
    }
  }, [darkstoreId, zoneId, darkstoreStatus, language, pathname, replace]);

  const setDarkstoreParams = ({ darkstoreId, zoneId }) => {
    localStorage.setItem('darkstoreId', darkstoreId);
    Cookies.set('darkstoreId', darkstoreId);
    localStorage.setItem('zoneId', zoneId);
    Cookies.set('zoneId', zoneId);

    sessionStorage.removeItem('showedDarkstoreStatus');

    if (pathname.includes('/technical-break')) {
      replace(joinPaths('/', language));
    }

    dispatch({ type: 'DARKSTORE_PARAMS_CHANGED', payload: { darkstoreId, zoneId } });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <DarkstoreContext.Provider
      value={{
        ...state,
        ...constants[darkstore.country],
        darkstore,
        setDarkstoreParams,
      }}
    >
      {children}
    </DarkstoreContext.Provider>
  );
};

export { DarkstoreContext, DarkstoreProvider };
