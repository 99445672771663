import React from 'react';
import { SvgIcon } from '../shared';

const ChevronDown = props => {
  return (
    <SvgIcon {...props}>
      <path d="M21.18 4.59l-9.18 9.16-9.18-9.16-2.82 2.82 12 12 12-12-2.82-2.82z"></path>
    </SvgIcon>
  );
};

export default ChevronDown;
