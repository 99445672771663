import React, { lazy, Suspense } from 'react';
import { LoadingIndicator } from 'components/shared';

export default importFn => {
  const LazyComponent = lazy(importFn);
  return props => (
    <Suspense fallback={<LoadingIndicator animation="border" variant="primary" />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
