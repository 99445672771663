import React from 'react';
import { SvgIcon } from '../shared';

const ArrowLeft = props => {
  return (
    <SvgIcon {...props}>
      <path d="M24 10.5h-18.255l8.385-8.385-2.13-2.115-12 12 12 12 2.115-2.115-8.37-8.385h18.255v-3z"></path>
    </SvgIcon>
  );
};

export default ArrowLeft;
