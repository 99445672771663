import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route } from 'react-router-dom';
import { Button, Modal, ModalTitle, ModalContent, LoadingIndicator } from 'components/shared';
import { useBrowserHistoryContext } from 'contexts/BrowserHistory';
import { useCustomerContext } from 'contexts/Customer';
import garnetSad from 'img/garnetSad.png';
import { joinPaths } from 'utils/helpers';
import styles from './PrivateRoute.module.scss';

const PrivateRoute = props => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { goBackOrReplace } = useBrowserHistoryContext();

  const { isAuthenticated, isLoading, customer } = useCustomerContext();

  const {
    location: { pathname },
  } = props;

  if (!isAuthenticated) {
    return (
      <Modal isOpened>
        <ModalTitle onClose={() => goBackOrReplace(joinPaths('/', language))} />
        <ModalContent>
          <img className={styles.image} src={garnetSad} alt="garnetSad" />
          <p className={styles.title}>{t('main:privateRoute.title')}</p>
          <div className={styles.content}>
            <p>{t('main:privateRoute.text')}</p>
            <Link
              to={joinPaths('/', language, 'login')}
              onClick={() => sessionStorage.setItem('redirectUrl', pathname)}
            >
              <Button size="large">{t('main:privateRoute.button')}</Button>
            </Link>
          </div>
        </ModalContent>
      </Modal>
    );
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (customer) {
    return <Route {...props} />;
  }

  return null;
};

export default PrivateRoute;
