import React from 'react';
import clsx from 'clsx';
import { Close as CloseIcon } from '../../icons';
import styles from './ModalTitle.module.scss';

const ModalTitle = ({
  children = null,
  className = null,
  classes = {},
  isTextContent = true,
  onClose = null,
}) => {
  return (
    <div className={clsx(styles.root, className, classes.root)}>
      {isTextContent ? (
        <span className={clsx(styles.text, classes.text)}>{children}</span>
      ) : (
        <div className={clsx(styles.content, classes.content)}>{children}</div>
      )}

      {onClose && (
        <CloseIcon className={clsx(styles.closeIcon, classes.closeIcon)} onClick={onClose} />
      )}
    </div>
  );
};

export default ModalTitle;
