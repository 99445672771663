import React from 'react';
import { SvgIcon } from '../shared';

const Umbrella = props => {
  return (
    <SvgIcon {...props}>
      <path d="M23.69 12.315c-0.343-5.868-5.212-10.535-11.239-10.758v-1.134c0-0.234-0.19-0.423-0.424-0.423s-0.423 0.189-0.423 0.423v1.132c-6.053 0.196-10.949 4.874-11.294 10.76-0.011 0.194 0.112 0.372 0.297 0.429s0.388-0.019 0.488-0.185c0.58-0.958 1.647-1.607 2.878-1.607 1.368-0 2.537 0.8 3.053 1.935 0.069 0.151 0.22 0.248 0.386 0.248s0.317-0.097 0.385-0.249c0.66-1.455 2.097-2.509 3.806-2.651v10.799c0 0.586-0.237 1.113-0.62 1.497s-0.911 0.62-1.497 0.62c-0.586-0-1.113-0.236-1.497-0.62s-0.62-0.911-0.62-1.497c0-0.234-0.19-0.424-0.424-0.424s-0.423 0.189-0.423 0.424c0.001 1.637 1.327 2.964 2.965 2.965s2.964-1.327 2.965-2.965v-10.793c1.685 0.16 3.098 1.205 3.751 2.645 0.068 0.151 0.219 0.249 0.385 0.249s0.317-0.097 0.385-0.248c0.516-1.135 1.685-1.936 3.053-1.935 1.231 0 2.299 0.648 2.878 1.607 0.1 0.166 0.302 0.243 0.488 0.185 0.185-0.058 0.308-0.235 0.297-0.429zM7.692 6.97c-0.35 1.323-0.583 2.823-0.669 4.43-0.764-0.797-1.847-1.294-3.049-1.294-1.006 0-1.928 0.35-2.651 0.931 0.804-4.227 4.187-7.58 8.512-8.432-0.169 0.177-0.331 0.371-0.485 0.583-0.69 0.952-1.25 2.247-1.657 3.782zM12 9.372c-1.646 0-3.121 0.716-4.119 1.849 0.152-2.405 0.647-4.542 1.346-6.105 0.395-0.886 0.857-1.585 1.336-2.045 0.239-0.23 0.482-0.4 0.721-0.512s0.475-0.165 0.715-0.166c0.241 0 0.476 0.054 0.715 0.166 0.418 0.194 0.849 0.573 1.251 1.127 0.604 0.829 1.138 2.039 1.523 3.5 0.317 1.198 0.534 2.566 0.628 4.032-0.998-1.131-2.472-1.846-4.117-1.846zM20.026 10.105c-1.202 0-2.286 0.498-3.050 1.295-0.14-2.582-0.654-4.891-1.43-6.63-0.393-0.879-0.853-1.612-1.382-2.165 4.325 0.852 7.71 4.205 8.513 8.432-0.722-0.581-1.644-0.931-2.651-0.931z"></path>
    </SvgIcon>
  );
};

export default Umbrella;
