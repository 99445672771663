import { useMutation } from 'react-query';
import api from 'utils/api';

const useCheckAddress = ({ onSuccess }) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: address => {
      return api.post('v2/darkstores', {
        address: address.name,
        lat: address.lat,
        lon: address.lon,
      });
    },
    onSuccess,
  });

  return { isChecking: isLoading, checkAddress: mutate };
};

export default useCheckAddress;
