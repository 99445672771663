import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { useDarkstoreContext } from 'contexts/Darkstore';
import api from 'utils/api';
import { calculateRating, getImageUrl } from 'utils/helpers';
import { mapProduct } from 'utils/mappers';

const getProduct = async ({ slug, darkstoreId }) => {
  const [product, reviews] = await Promise.all([
    api.get(`products/${slug}/${darkstoreId}`),
    api.get(`products/rating/${slug}`, { darkstoreId }),
  ]);

  if (!product.id) {
    throw { code: 404, message: 'Данного товара не существует' };
  }

  return {
    ...mapProduct(product),
    rating: product.rating || calculateRating(reviews),
    reviews: reviews.map(review => ({
      ...review,
      photo: getImageUrl(review.photo),
      date: format(parseISO(review.date), 'dd.MM.yyyy'),
    })),
  };
};

const getInitialData = slug => {
  const product = window.SERVER_DATA?.product;
  if (!product || product.slug !== slug) {
    return undefined;
  }
  return mapProduct({ ...window.SERVER_DATA.product, reviews: [] });
};

const useProduct = slug => {
  const { replace } = useHistory();

  const { darkstoreId } = useDarkstoreContext();

  const { isLoading, data: product = { reviews: [] } } = useQuery(
    [`products/${slug}`, { darkstoreId }],
    () => getProduct({ slug, darkstoreId }),
    {
      enabled: !!slug,
      initialData: getInitialData(slug),
      onError: error => {
        if (error.code === 404) {
          replace('/not-found');
        }
      },
    },
  );

  return { isLoading, product };
};

export default useProduct;
