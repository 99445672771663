import React from 'react';
import clsx from 'clsx';
import { Button } from '../Button';
import './up-down-button.scss';

const UpDownButton = ({ size = 'medium', value, minValue = 0, maxValue = 100, onClick }) => {
  return (
    <div className={clsx('b-up-down-button', `b-up-down-button--size--${size}`)}>
      <Button disabled={value === minValue} onClick={e => onClick(value - 1, e)}>
        {' '}
        -{' '}
      </Button>
      <span>{value}</span>
      <Button disabled={value === maxValue} onClick={e => onClick(value + 1, e)}>
        {' '}
        +{' '}
      </Button>
    </div>
  );
};

export default UpDownButton;
