import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker, Polygon, useLoadScript } from '@react-google-maps/api';
import config from 'config';
import { Button, LoadingIndicator } from 'components/shared';
import { OPTIONS } from 'constants/gmaps';
import addressFailed from 'img/address/failed.png';
import addressOk from 'img/address/ok.png';
import usePolygons from './usePolygons';
import styles from './Map.module.scss';

const CONTAINER_STYLE = {
  height: '100%',
  minHeight: '360px',
};

const Map = ({ darkstoreId, zoneId, currency, center, zoom, position, onLoaded, onWait }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { isLoading, polygons } = usePolygons({ onSuccess: onLoaded });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.GOOGLE_API_KEY,
    language,
    region: language.toUpperCase(),
  });

  if (isLoading || !isLoaded) {
    return (
      <div style={CONTAINER_STYLE}>
        <LoadingIndicator />
      </div>
    );
  }

  const hasPosition = !!(position.lat && position.lng);
  const zone = polygons.find(p => p.darkstoreId === darkstoreId && p.zoneId === zoneId) ?? {
    active: false,
  };

  return (
    <GoogleMap
      mapContainerStyle={CONTAINER_STYLE}
      center={hasPosition ? position : center}
      options={OPTIONS}
      zoom={hasPosition ? 14 : zoom}
    >
      {polygons.map(polygon => (
        <Polygon
          key={polygon.id}
          path={polygon.points}
          options={{
            fillColor: `rgb(${polygon.color})`,
            fillOpacity: 1,
            strokeColor: `rgb(${polygon.color})`,
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />
      ))}

      {position && <Marker position={position} />}

      {hasPosition && zone.active && (
        <div className={styles.tooltip}>
          <img src={addressOk} alt="AddressOk" />
          <p className={styles.tooltipTitle}>{t('address:tooltips.deliverable.0')}</p>
          <p className={styles.tooltipText}>
            {t('address:tooltips.deliverable.1')}{' '}
            <span>
              {t('address:tooltips.deliverable.2', { deliveryTime: zone.defaultDeliveryTime })}
            </span>
          </p>
          <span className={styles.tooltipBadge}>
            {t('address:tooltips.deliverable.3')}{' '}
            <b>
              {zone.minSum} {currency}
            </b>
          </span>
        </div>
      )}

      {hasPosition && !zone.active && (
        <div className={styles.tooltip}>
          <img src={addressFailed} alt="AddressFailed" />
          <p className={styles.tooltipText}>{t('address:tooltips.notDeliverable.0')}</p>
          <Button variant="outlined" color="primary" size="small" onClick={onWait}>
            {t('address:tooltips.notDeliverable.1')}
          </Button>
        </div>
      )}
    </GoogleMap>
  );
};

export default Map;
