import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
  const { pathname, state: { scrollRestoration } = {} } = useLocation();

  useEffect(() => {
    if (scrollRestoration !== 'none') {
      document.body.scrollTo(0, 0);
    }
  }, [pathname, scrollRestoration]);

  return null;
};

export default useScrollToTop;
