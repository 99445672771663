import { useEffect } from 'react';

const useClickOutside = ({ enabled = true, targets = [], onClickOutside = () => {} }) => {
  useEffect(() => {
    if (enabled) {
      const clickHandler = e => {
        if (targets.length > 0 && targets.every(target => target && !target.contains(e.target))) {
          onClickOutside();
        }
      };
      document.addEventListener('mousedown', clickHandler);
      document.addEventListener('touchstart', clickHandler);
      return () => {
        document.removeEventListener('mousedown', clickHandler);
        document.removeEventListener('touchstart', clickHandler);
      };
    }
  }, [enabled, targets, onClickOutside]);
};

export default useClickOutside;
