import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { parse, format, isValid } from 'date-fns';
import { LoyaltyProgramModal } from 'components';
import { useAddressContext } from 'contexts/Address';
import { useDarkstoreContext } from 'contexts/Darkstore';
import { subscribeToNotifications } from 'lib/firebase';
import api from 'utils/api';
import { getImageUrl } from 'utils/helpers';
import { closeModal, openModal } from 'utils/modals';

const useCustomer = ({ enabled }) => {
  const { replace } = useHistory();
  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const { darkstoreId, zoneId, darkstore, setDarkstoreParams } = useDarkstoreContext();
  const { address, setAddress } = useAddressContext();

  const { data, isLoading } = useQuery({
    queryKey: 'account',
    queryFn: async () => {
      const customer = await api.get('customers');
      if (customer.isNew) {
        return customer;
      }
      const addresses = await api.get('customers/addresses');
      const parsedDate = parse(customer.birthday, 'yyyy-MM-dd', new Date());
      return {
        ...customer,
        birthday: isValid(parsedDate) ? format(parsedDate, 'dd.MM.yyyy') : '',
        photo: getImageUrl(customer.photo),
        addresses,
      };
    },
    enabled,
    onSuccess: customer => {
      if (customer.isNew) {
        replace('/registration');
        return;
      } else {
        const isLoyaltyModalViewed = localStorage.getItem('isLoyaltyModalViewed1');
        if (
          darkstore.country === 'by' &&
          !isLoyaltyModalViewed &&
          !customer.loyalty.isParticipation
        ) {
          const { modalId } = openModal(
            <LoyaltyProgramModal
              onClose={() => {
                localStorage.setItem('isLoyaltyModalViewed1', true);
                closeModal(modalId);
              }}
            />,
          );
        }
      }

      if (['/login', '/registration'].some(url => pathname.includes(url))) {
        const redirectUrl = sessionStorage.getItem('redirectUrl');
        if (redirectUrl) {
          sessionStorage.removeItem('redirectUrl');
          replace(redirectUrl);
        } else {
          replace('/');
        }
      }

      if (
        (customer.darkstoreId && customer.darkstoreId !== darkstoreId) ||
        (customer.zoneId && customer.zoneId !== zoneId)
      ) {
        setDarkstoreParams({ darkstoreId: customer.darkstoreId, zoneId: customer.zoneId });
      }

      const activeAddress = customer.addresses.find(a => a.isActive);

      if (!Object.is(activeAddress, address)) {
        setAddress(activeAddress);
      }

      subscribeToNotifications(queryClient);
    },
  });

  return { isLoading, customer: data };
};

export default useCustomer;
