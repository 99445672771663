import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Button, Modal, ModalTitle, ModalContent } from 'components/shared';
import sleep from 'img/darkstore/sleep.png';
import styles from './DarkstoreSleepModal.module.scss';

const DarkstoreSleepModal = ({ startWork, finishWork, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpened onClose={onClose}>
      <ModalTitle onClose={onClose} />
      <ModalContent>
        <img className={styles.image} src={sleep} alt="DarkstoreSleep" />
        <p className={styles.title}>{t('statuses:darkstore.sleepTitle')}</p>
        <div className={styles.content}>
          <p>
            {t('statuses:darkstore.sleepText', {
              startWork: format(startWork, 'HH:mm'),
              finishWork: format(finishWork, 'HH:mm'),
            })}
          </p>
          <Button size="large" onClick={onClose}>
            {t('statuses:darkstore.goodNight')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default DarkstoreSleepModal;
