import React, { Fragment } from 'react';
import { CardLoader } from 'components';
import { useCartContext } from 'contexts/Cart';
import { useDarkstoreContext } from 'contexts/Darkstore';
import { ProductCard } from './ProductCard';
import styles from './ProductCardList.module.scss';

const ProductCardList = ({ isLoading, products, banner, queryKey, onCardClick }) => {
  const {
    darkstore: { currency },
  } = useDarkstoreContext();
  const { products: cartProducts, addProduct, setProductQty } = useCartContext();

  return (
    <div className={styles.root}>
      {isLoading
        ? [1, 2, 3, 4, 5, 6, 7, 8].map(item => <CardLoader key={item} />)
        : products.map((product, index) => {
            return (
              <Fragment key={`${product.id}-${index}`}>
                {banner && index === 0 && (
                  <div className={styles.banner}>
                    <img
                      // src={`${banner.webImage}/w_175,h_320,c_scale,c_fill,f_png,q_70`}
                      src={banner.webImage}
                      alt={banner.name}
                      width={175}
                      height={320}
                    />
                  </div>
                )}
                <ProductCard
                  product={product}
                  currency={currency}
                  queryKey={queryKey}
                  inCart={cartProducts.find(cartProduct => cartProduct.id === product.id)}
                  onClick={onCardClick}
                  onCartClick={addProduct}
                  onUpDownClick={setProductQty}
                />
              </Fragment>
            );
          })}
    </div>
  );
};

export default ProductCardList;
