export default {
  by: {
    defaultDarkstoreId: 2,
    mapCenter: { lat: 53.902512, lng: 27.561481 },
    mapZoom: 11,
    aboutPageCities: [
      { key: 'minsk', country: 'by', position: { lat: 53.902512, lng: 27.561481 } },
      // { key: 'spb', country: 'ru', position: { lat: 59.939095, lng: 30.315868 } },
      // { key: 'kiev', country: 'ua', position: { lat: 50.45447, lng: 30.52418 } },
      // { key: 'nur-sultan', country: 'kz', position: { lat: 51.169392, lng: 71.449074 } },
    ],
    gtmIds: ['GTM-5N2S78VS'],
  },

  // ru: {
  //   defaultDarkstoreId: 100,
  //   mapCenter: { lat: 59.939095, lng: 30.315868 },
  //   mapZoom: 10,
  //   aboutPageCities: [
  //     { key: 'spb', country: 'ru', position: { lat: 59.939095, lng: 30.315868 } },
  //     { key: 'minsk', country: 'by', position: { lat: 53.902512, lng: 27.561481 } },
  //     { key: 'kiev', country: 'ua', position: { lat: 50.45447, lng: 30.52418 } },
  //     { key: 'nur-sultan', country: 'kz', position: { lat: 51.169392, lng: 71.449074 } },
  //   ],
  //   gtmIds: ['GTM-K6892NQ'],
  // },

  // ua: {
  //   defaultDarkstoreId: 200,
  //   mapCenter: { lat: 50.45447, lng: 30.52418 },
  //   mapZoom: 10,
  //   aboutPageCities: [
  //     { key: 'kiev', country: 'ua', position: { lat: 50.45447, lng: 30.52418 } },
  //     { key: 'minsk', country: 'by', position: { lat: 53.902512, lng: 27.561481 } },
  //     { key: 'spb', country: 'ru', position: { lat: 59.939095, lng: 30.315868 } },
  //     { key: 'nur-sultan', country: 'kz', position: { lat: 51.169392, lng: 71.449074 } },
  //   ],
  //   gtmIds: ['GTM-MHSQBDJ'],
  // },

  // kz: {
  //   defaultDarkstoreId: 300,
  //   mapCenter: { lat: 51.169392, lng: 71.449074 },
  //   mapZoom: 11,
  //   aboutPageCities: [
  //     { key: 'nur-sultan', country: 'kz', position: { lat: 51.169392, lng: 71.449074 } },
  //     { key: 'minsk', country: 'by', position: { lat: 53.902512, lng: 27.561481 } },
  //     { key: 'spb', country: 'ru', position: { lat: 59.939095, lng: 30.315868 } },
  //     { key: 'kiev', country: 'ua', position: { lat: 50.45447, lng: 30.52418 } },
  //   ],
  //   gtmIds: ['GTM-5LRHS96'],
  // },

  pl: {
    defaultDarkstoreId: 400,
    mapCenter: { lat: 53.13333, lng: 23.16433 },
    mapZoom: 11,
    aboutPageCities: [
      { key: 'belostok', country: 'pl', position: { lat: 53.13333, lng: 23.16433 } },
      // { key: 'minsk', country: 'by', position: { lat: 53.902512, lng: 27.561481 } },
      // { key: 'kiev', country: 'ua', position: { lat: 50.45447, lng: 30.52418 } },
      // { key: 'nur-sultan', country: 'kz', position: { lat: 51.169392, lng: 71.449074 } },
    ],
    //gtmIds: ['GTM-W849R4X'],
  },
};
