import React from 'react';
import { SvgIcon } from '../shared';

const Notifications = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12 24c1.354 0 2.462-1.108 2.462-2.462h-4.923c0 1.354 1.108 2.462 2.462 2.462zM19.385 16.615v-6.154c0-3.778-2.006-6.942-5.538-7.778v-0.837c0-1.022-0.825-1.846-1.846-1.846s-1.846 0.825-1.846 1.846v0.837c-3.52 0.837-5.538 3.988-5.538 7.778v6.154l-2.462 2.462v1.231h19.692v-1.231l-2.462-2.462zM16.923 17.846h-9.846v-7.385c0-3.052 1.858-5.538 4.923-5.538s4.923 2.486 4.923 5.538v7.385z"></path>
    </SvgIcon>
  );
};

export default Notifications;
