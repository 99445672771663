import Axios from 'axios';
import config from 'config';

const AxiosInstance = Axios.create({ baseURL: config.API_URL });

const createStringParams = (params = {}) => {
  if (Object.keys(params).length === 0) {
    return '';
  }
  const stringParams = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
  return `?${stringParams}`;
};

const createConfig = headers => {
  const token = localStorage.getItem('token') || '';
  return {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: token,
      language: localStorage.getItem('i18nextLng'),
      ...headers,
    },
  };
};

const throwError = response => {
  if (response) {
    const { status, data } = response;

    let err = { code: status, responce: true };

    const token = localStorage.getItem('token');
    if (token && status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('exp');
      localStorage.removeItem('refreshToken');
      window.location.pathname = '/login';
    }

    err.message = data.error || data.message || data.mes;
    err.data = data;

    throw err;
  }
  const err = { message: 'Server unavailable' };
  throw err;
};

const api = {
  get: async (url, params) => {
    try {
      const { data } = await AxiosInstance.get(
        `${url}${createStringParams(params)}`,
        createConfig(),
      );
      return data;
    } catch ({ response }) {
      return throwError(response);
    }
  },
  post: async (url, body, headers) => {
    try {
      const { data } = await AxiosInstance.post(url, body, createConfig(headers));
      return data;
    } catch ({ response }) {
      return throwError(response);
    }
  },
  put: async (url, body) => {
    try {
      const { data } = await AxiosInstance.put(url, body, createConfig());
      return data;
    } catch ({ response }) {
      return throwError(response);
    }
  },
  patch: async (url, body) => {
    try {
      const { data } = await AxiosInstance.patch(url, body, createConfig());
      return data;
    } catch ({ response }) {
      return throwError(response);
    }
  },
  delete: async (url, params) => {
    try {
      const { data } = await AxiosInstance.delete(
        `${url}${createStringParams(params)}`,
        createConfig(),
      );
      return data;
    } catch ({ response }) {
      return throwError(response);
    }
  },
};

export default api;
