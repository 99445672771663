import config from 'config';

const mobileAppsLinks = {
  sosedi: {
    android: 'https://play.google.com/store/apps/details?id=com.sosedi.delivery',
    ios: 'https://apps.apple.com/us/app/%D1%81%D0%BE%D1%81%D0%B5%D0%B4%D0%B8-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0/id6470782966',
  },
  emart: {
    android: 'https://play.google.com/store/apps/details?id=com.kz.bazarstore',
    ios: 'https://apps.apple.com/us/app/emart-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D0%BE%D0%B2/id1573241080',
  },
};

export default mobileAppsLinks[config.BUILD_TYPE];
