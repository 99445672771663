import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalTitle, ModalContent } from 'components/shared';
import { useDarkstoreContext } from 'contexts/Darkstore';
import addressFailed from 'img/address/failed.png';
import qtyChanged from 'img/qtyChanged.png';
import styles from './CartStatusModal.module.scss';

const CartStatusModal = ({ sold, oldSum, sum, onClose }) => {
  const { t } = useTranslation();

  const {
    darkstore: { currency },
  } = useDarkstoreContext();

  if (sold.length > 0) {
    return (
      <Modal isOpened onClose={onClose}>
        <ModalTitle isTextContent={false} onClose={onClose}>
          <div className={styles.title}>
            <span> {t('statuses:cart.sold')}</span>
            <img src={addressFailed} alt="AddressFailed" height={24} />
          </div>
        </ModalTitle>
        <ModalContent>
          <div className={styles.content}>
            <div className={styles.productsList}>
              {sold.map(product => (
                <div className={styles.product} key={product.id}>
                  <div className={styles.productImage}>
                    <img src={product.img} alt="ProductImage" />
                  </div>
                  <div className={styles.productInfo}>
                    <p className={styles.productTitle}>{product.name}</p>
                    <p className={styles.productWeight}>{product.weight}</p>
                  </div>
                  <div className={styles.price}>
                    {product.oldPrice && (
                      <p className={styles.productPriceOld}>
                        {product.oldPrice}
                        {currency}
                      </p>
                    )}
                    <p className={styles.productPriceCurrent}>
                      {product.currentPrice}
                      {currency}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {/* <img className={styles.image} src={qtyChanged} alt="QtyChanged" />
            <p className={styles.title}>{t('statuses:cart')}</p> */}
            <div className={styles.early}>
              {t('statuses:cart.early')}:{' '}
              <span>
                {oldSum}
                {currency}
              </span>
            </div>
            <div className={styles.total}>
              {t('statuses:cart.total')}:{' '}
              <span>
                {sum}
                {currency}
              </span>
            </div>
            <Button className={styles.button} size="large" onClick={onClose}>
              {t('shared:buttons.well')}
            </Button>
          </div>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpened onClose={onClose}>
      <ModalTitle onClose={onClose} />
      <ModalContent>
        <div className={styles.content}>
          <img className={styles.image} src={qtyChanged} alt="QtyChanged" />
          <p className={styles.title}>{t('statuses:cart.change')}</p>
          <Button className={styles.button} size="large" onClick={onClose}>
            {t('shared:buttons.well')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default CartStatusModal;
