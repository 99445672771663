import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared';
import noAvatar from 'img/noAvatar.png';
import noReviews from 'img/noReviews.png';
import { Rating } from '../Rating';
import styles from './Reviews.module.scss';

const Reviews = ({ reviews = [], onAddClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <p className={styles.title}>
          {t('product:reviewsAboutProduct')}
          <span>({reviews.length})</span>
        </p>
        {onAddClick && (
          <Button variant="text" onClick={onAddClick}>
            + {t('product:addReview')}
          </Button>
        )}
      </div>

      <div className={styles.list}>
        {reviews.length > 0 ? (
          reviews.map((review, index) => (
            <div className={styles.review} key={`review-${index}`}>
              <img
                className={styles['review__avatar']}
                src={review.photo || noAvatar}
                alt="Avatar"
              />
              <div className={styles['review__body']}>
                <Rating value={review.rate} size={16} />
                <div className={styles['review__title']}>
                  {review.name && <span className={styles['review__author']}>{review.name}</span>}
                  <span className={styles['review__date']}>{review.date}</span>
                </div>
                {review.comment && <p className={styles['review__comment']}>{review.comment}</p>}
              </div>
            </div>
          ))
        ) : (
          <div className={styles['no-reviews']}>
            <img src={noReviews} alt="NoReviews" />
            <p className={styles['no-reviews__title']}>{t('product:noReviewsTitle')}</p>
            <p className={styles['no-reviews__description']}>{t('product:noReviewsText')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Reviews;
