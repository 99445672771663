import React from 'react';
import styles from './MenuItem.module.scss';

const MenuItem = ({ icon, text, onClick }) => {
  return (
    <button className={styles.root} onClick={onClick}>
      {icon}
      {text}
    </button>
  );
};

export default MenuItem;
