import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const GLOBAL_MODAL_WHITELIST = [
  '/',
  '/special',
  '/categories',
  '/favorites',
  '/products',
  '/account',
];

const useCanDisplayGlobalModal = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const { pathname } = useLocation();

  const canDisplayGlobalModals = useMemo(
    () =>
      GLOBAL_MODAL_WHITELIST.some(
        url =>
          url === pathname.replace(`/${language}`, '') ||
          pathname.includes(GLOBAL_MODAL_WHITELIST[GLOBAL_MODAL_WHITELIST.length - 1]),
      ),
    [pathname, language],
  );

  return canDisplayGlobalModals;
};

export default useCanDisplayGlobalModal;
