import { useQuery } from 'react-query';
import { getDay, addMinutes, format, parse } from 'date-fns';
import api from 'utils/api';
import { getImageUrl } from 'utils/helpers';

const mapDarkstore = ({ darkstore, paymentMethods, paidDelivery }) => {
  const {
    schedule,
    darkstore_currency,
    darkstore_start_work,
    darkstore_finish_work,
    delivery,
    status,
    tel,
    darkstore_country,
    darkstore_language,
    defaultCart,
    ...other
  } = darkstore;

  const now = new Date();
  const dayOfWeek = getDay(now);
  const daySchedule = schedule[dayOfWeek] || schedule[7];
  const startWork = parse(
    daySchedule.start === '24:00' ? '23:59' : daySchedule.start,
    'HH:mm',
    now,
  );
  const finishWork = parse(
    daySchedule.finish === '24:00' ? '23:59' : daySchedule.finish,
    'HH:mm',
    now,
  );

  const isWorking = startWork < now && now < finishWork;
  const fastestDelivery = addMinutes(now, delivery);
  const isDeliverableToday = startWork < fastestDelivery && fastestDelivery < finishWork;

  const deliveryTimes = { today: [], tomorrow: [] };

  if (isDeliverableToday) {
    if (status === 2) {
      deliveryTimes.today.push(`${delivery} mins`);
    } else {
      deliveryTimes.today.push('now');
    }
  }

  for (let i = 0; i < 48; i++) {
    const tmp = i === 0 ? startWork : addMinutes(startWork, i * 30 - (startWork.getMinutes() % 30));
    if (tmp <= finishWork) {
      deliveryTimes.tomorrow.push(format(tmp, 'HH:mm'));
      if (tmp >= addMinutes(now, delivery)) {
        deliveryTimes.today.push(format(tmp, 'HH:mm'));
      }
    } else {
      break;
    }
  }

  return {
    currency: ['BYN', 'RUB'].includes(darkstore_currency) ? 'р' : darkstore_currency,
    currencyISO: darkstore_currency,
    delivery,
    deliveryTimes,
    startWork,
    finishWork,
    status: isWorking ? status : -1,
    tel: tel.map(telItem => ({ ...telItem, icon: getImageUrl(telItem.icon) })),
    paymentMethods: paymentMethods.filter(paymentMethod => [1, 2, 3, 7].includes(paymentMethod.id)),
    country: darkstore_country.toLowerCase(),
    language: darkstore_language.toLowerCase(),
    schedule,
    defaultCart: defaultCart.map(product => ({
      id: product.id,
      barcode: product.cod,
      name: product.name,
      img: getImageUrl(product.img),
      currentPrice: product.price,
      maxQty: product.qty,
      qty: 1,
      readOnly: true,
    })),
    paidDelivery,
    ...other,
  };
};

const getDarkstore = async ({ darkstoreId, zoneId }) => {
  const searchParams = new URLSearchParams();
  if (zoneId) {
    searchParams.append('zoneId', zoneId);
  }
  const searchString = `?${searchParams.toString()}`;

  const [darkstore, paymentMethods, paidDelivery] = await Promise.all([
    api.get(`darkstores/${darkstoreId}${searchString}`),
    api.get(`paymentMethods/${darkstoreId}${searchString}`),
    api.get(`/v2/delivery-cost/${darkstoreId}`),
  ]);

  return mapDarkstore({ darkstore, paymentMethods, paidDelivery });
};

const getInitialData = ({ darkstoreId, zoneId }) => {
  const darkstore = window.SERVER_DATA?.darkstore;
  if (!darkstore || darkstore.id !== darkstoreId || darkstore.zoneId !== zoneId) {
    return undefined;
  }
  return mapDarkstore({ darkstore, paymentMethods: [], paidDelivery: undefined });
};

const useDarkstore = ({ darkstoreId, zoneId }) => {
  const { data, isLoading } = useQuery(
    [`darkstores`, { darkstoreId, zoneId }],
    () => getDarkstore({ darkstoreId, zoneId }),
    {
      enabled: !!darkstoreId,
      initialData: getInitialData({ darkstoreId, zoneId }),
      keepPreviousData: true,
      refetchInterval: 10 * 60 * 1000,
      onError: error => {
        if (error.data) {
          localStorage.removeItem('darkstoreId');
          localStorage.removeItem('zoneId');
          localStorage.removeItem('address');
          window.location.reload();
        }
      },
    },
  );

  return { isLoading, darkstore: data };
};

export default useDarkstore;
