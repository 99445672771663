import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEditLoyalty from 'components/LoyaltyProgramModal/useEditLoyalty';
import {
  Button,
  LoadingIndicator,
  Modal,
  ModalContent,
  ModalTitle,
  Switch,
} from 'components/shared';
import { useCustomerContext } from 'contexts/Customer';
import { useDarkstoreContext } from 'contexts/Darkstore';
import styles from './BonusesProgramModal.module.scss';

const BonusesProgramModal = ({ onClose }) => {
  const { t } = useTranslation();
  const { darkstore } = useDarkstoreContext();
  const { customer } = useCustomerContext();
  const [checked, setChecked] = useState([
    customer.loyalty.isParticipation,
    customer.loyalty.isResearch,
    customer.loyalty.isMessages,
    customer.loyalty.isGames,
  ]);
  const { isEditing, edit } = useEditLoyalty({ onSuccess: onClose });
  return (
    <Modal className={styles.root} isOpened onClose={onClose}>
      <ModalTitle className={styles.title} onClose={onClose}>
        {t('account:bonuses.title')}
      </ModalTitle>
      {isEditing ? (
        <ModalContent>
          <LoadingIndicator />
        </ModalContent>
      ) : (
        <ModalContent>
          {darkstore.country === 'by' ? (
            <>
              <p className={styles.text}>
                Накопленными бонусами можно оплатить до 99% стоимости всей покупки в Соседи-доставке
                или магазинах Соседи: 1 бонус = 1 рубль.
              </p>
              <p className={styles.text}>
                Для использования накопленных бонусов необходимо зарегистрироваться в Купилке.
              </p>
              <p className={styles.text}>
                Полные правила бонусной программы{' '}
                <a href="https://sosedi.by/local/include/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%9A%D1%83%D0%BF%D0%B8%D0%BB%D0%BA%D0%B82%20.PDF">
                  доступны по ссылке
                </a>
                .
              </p>
              <div className={styles.switch}>
                <Switch
                  size="large"
                  checked={checked[0]}
                  onChange={e => {
                    const newChecked = [e.target.checked, false, false, false];
                    setChecked(newChecked);
                  }}
                />
                <span>Регистрация в бонусной программе Купилка</span>
              </div>
              <div className={styles.switch}>
                <Switch
                  size="large"
                  checked={checked[1]}
                  onChange={e => {
                    const newChecked = [true, e.target.checked, checked[2], checked[3]];
                    setChecked(newChecked);
                  }}
                />
                <span>
                  Проведение маркетинговых исследований для предоставления дополнительных
                  преимуществ в бонусной программе «Купилка» и повышения качества обслуживания
                </span>
              </div>
              <div className={styles.switch}>
                <Switch
                  size="large"
                  checked={checked[2]}
                  onChange={e => {
                    const newChecked = [true, checked[1], e.target.checked, checked[3]];
                    setChecked(newChecked);
                  }}
                />
                <span>
                  Предоставление персонализированной информации новостного и рекламного характера,
                  посредством электронной почты, Viber, СМС, Push
                </span>
              </div>
              <div className={styles.switch}>
                <Switch
                  size="large"
                  checked={checked[3]}
                  onChange={e => {
                    const newChecked = [true, checked[1], checked[2], e.target.checked];
                    setChecked(newChecked);
                  }}
                />
                <span>Участие в рекламных играх</span>
              </div>
            </>
          ) : (
            <>
              <p className={styles.text}>{t('account:bonuses.description.0')}</p>
              <p className={styles.text}>
                {t('account:bonuses.description.1')}{' '}
                <span>{t('account:bonuses.description.2')}</span>{' '}
                {t('account:bonuses.description.3')}
              </p>
            </>
          )}
          <Button
            className={styles.button}
            size="large"
            onClick={() => {
              if (darkstore.country === 'by') {
                edit({
                  loyalty: {
                    isParticipation: checked[0],
                    isResearch: checked[1],
                    isMessages: checked[2],
                    isGames: checked[3],
                  },
                  customer,
                });
              } else {
                onClose();
              }
            }}
          >
            Продолжить
          </Button>
        </ModalContent>
      )}
    </Modal>
  );
};

export default BonusesProgramModal;
