import React from 'react';
import { SvgIcon } from '../shared';

const Market = props => {
  return (
    <SvgIcon {...props}>
      <path d="M22.91 10.795h-2.786c-0.176-1.218-1.134-2.185-2.347-2.376v-4.629c0-0.259-0.21-0.469-0.469-0.469h-4.1c-0.147-1.856-1.703-3.321-3.596-3.321h-0.078c-1.893 0-3.449 1.465-3.596 3.321h-4.099c-0.259 0-0.469 0.21-0.469 0.469v18.7c0 0.833 0.678 1.51 1.51 1.51h19.274c0.675 0 1.223-0.549 1.223-1.223v-11.513c0-0.259-0.21-0.469-0.469-0.469zM19.172 10.795h-3.726c0.186-0.841 0.937-1.473 1.834-1.473h0.058c0.897 0 1.648 0.632 1.834 1.473zM9.535 0.938h0.078c1.376 0 2.512 1.046 2.655 2.384h-5.389c0.144-1.338 1.28-2.384 2.655-2.384zM2.309 22.49v-18.231h3.618v1.573h-0.273c-0.622 0.025-0.621 0.913 0 0.937h1.484c0.622-0.025 0.621-0.913 0-0.937h-0.273v-1.573h5.42v1.573h-0.273c-0.622 0.025-0.621 0.913 0 0.937h1.484c0.622-0.025 0.621-0.913 0-0.937h-0.273v-1.573h3.618v4.161c-1.213 0.191-2.17 1.159-2.346 2.376h-2.786c-0.259 0-0.469 0.21-0.469 0.469v11.513c0 0.098 0.012 0.194 0.034 0.286h-8.391c-0.316 0-0.573-0.257-0.573-0.573zM22.442 22.777c0 0.157-0.128 0.286-0.286 0.286h-9.694c-0.157 0-0.286-0.128-0.286-0.286v-11.044h2.288v1.421c0.025 0.622 0.913 0.621 0.937 0v-1.421h3.815v1.421c0.025 0.622 0.913 0.621 0.937 0v-1.421h2.287v11.044z"></path>
      <path d="M7.585 8.645c-0.089-0.116-0.226-0.184-0.372-0.184h-1.636c-0.146 0-0.283 0.068-0.372 0.184l-0.967 1.263c-0.063 0.082-0.097 0.182-0.097 0.285v3.551c0 0.259 0.21 0.469 0.469 0.469h3.571c0.259 0 0.469-0.21 0.469-0.469v-3.551c0-0.103-0.034-0.203-0.097-0.285l-0.967-1.263zM7.712 13.275h-2.633v-2.923l0.73-0.953h1.173l0.73 0.953v2.923z"></path>
    </SvgIcon>
  );
};

export default Market;
