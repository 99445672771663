import { useQueryClient, useMutation } from 'react-query';
import api from 'utils/api';

const setPaymentCard = token => api.put(`/customers/cardsChange/${token}`);

const useSetPaymentCard = () => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(setPaymentCard, {
    onMutate: token => {
      queryClient.setQueryData('account', account => {
        const index = account.cards.findIndex(card => card.token === token);
        const newCards = account.cards.map(card => ({ ...card, isActive: 0 }));
        newCards[index].isActive = 1;
        return { ...account, cards: newCards };
      });
    },
    onError: () => queryClient.invalidateQueries('account'),
  });

  return { isSelecting: isLoading, setPaymentCard: mutate };
};

export default useSetPaymentCard;
