import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import config from 'config';
import { Button } from 'components/shared';
import { useCustomerContext } from 'contexts/Customer';
import logoEmart from 'img/logoEmart.png';
import logoSosedi from 'img/logoSosedi.png';
import { joinPaths } from 'utils/helpers';
import { HeaderAddress } from './HeaderAddress';
import { HeaderCart } from './HeaderCart';
import { HeaderDelivery } from './HeaderDelivery';
import { HeaderDesktopMenu } from './HeaderDesktopMenu';
import { HeaderMobileMenu } from './HeaderMobileMenu';
import styles from './Header.module.scss';

const Header = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { pathname } = useLocation();
  const { customer } = useCustomerContext();

  return (
    <header className={styles.root}>
      {pathname.includes('mobile-app') ? (
        <div className={styles.content}>
          <Link to={joinPaths('/', language)}>
            {config.BUILD_TYPE === 'sosedi' && (
              <img src={logoSosedi} alt="SosediLogo" width={142} height={41} />
            )}
            {config.BUILD_TYPE === 'emart' && (
              <img src={logoEmart} alt="EmartLogo" width={116} height={60} />
            )}
          </Link>
        </div>
      ) : (
        <div className={styles.content}>
          <nav className={styles.side}>
            <Link to={joinPaths('/', language)}>
              {config.BUILD_TYPE === 'sosedi' && (
                <img src={logoSosedi} alt="SosediLogo" width={142} height={41} />
              )}
              {config.BUILD_TYPE === 'emart' && (
                <img src={logoEmart} alt="EmartLogo" width={116} height={60} />
              )}
            </Link>
            <Link className={styles.link} to={joinPaths('/', language)}>
              {t('main:header.links.0')}
            </Link>
          </nav>

          <div className={styles.side}>
            <HeaderDelivery className={styles.desktopOnly} />
            <HeaderAddress className={styles.desktopOnly} />

            <HeaderCart />
            <HeaderMobileMenu />

            <div className={styles.desktopOnly}>
              {customer ? (
                <HeaderDesktopMenu />
              ) : (
                <Link
                  to={joinPaths('/', language, 'login')}
                  onClick={() => sessionStorage.setItem('redirectUrl', pathname)}
                >
                  <Button>{t('main:header.login')}</Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
