import React from 'react';
import { SvgIcon } from '../shared';

const ChevronUp = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12 4.59l-12 12 2.82 2.82 9.18-9.16 9.18 9.16 2.82-2.82z"></path>
    </SvgIcon>
  );
};

export default ChevronUp;
