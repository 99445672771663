import copy from 'copy-to-clipboard';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import config from 'config';

export const copyToClipboard = str => {
  copy(str);
  // await navigator.clipboard.writeText(str);
};

export const getImageUrl = rawUrl => {
  if (!rawUrl) {
    return null;
  }
  if (rawUrl.includes('base64') || rawUrl.includes('http')) {
    return rawUrl;
  }
  if (rawUrl.startsWith('/')) {
    return `${config.FILESTORAGE_URL}${rawUrl}`;
  }
  return `${config.FILESTORAGE_URL}/${rawUrl}`;
};

export const calculateRating = reviews => {
  if (!reviews || reviews.length === 0) {
    return 0;
  }
  return Math.floor(reviews.reduce((acc, review) => (acc += review.rate), 0) / reviews.length);
};

export const sortByZeroQty = products => {
  const zeroQtyProducts = [];
  const otherProducsts = [];
  products.forEach(product => {
    if (product.qty <= 0) {
      zeroQtyProducts.push(product);
    } else {
      otherProducsts.push(product);
    }
  });
  return [...otherProducsts, ...zeroQtyProducts];
};

export const capitalize = string => {
  return string ? `${string[0].toUpperCase()}${string.slice(1)}` : string;
};

export const round = (value, fractionDigits = 2) => {
  return value ? Number(value.toFixed(fractionDigits)) : value;
};

export const isSafari = () => {
  return (
    navigator.userAgent.includes('Safari') &&
    !navigator.userAgent.includes('Chrome') &&
    !navigator.userAgent.includes('CriOS')
  );
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

// TODO: remove it
export const validatePhone = text => isValidPhoneNumber(`+${text}`);

export const joinPaths = (...args) => {
  const joined = args
    .filter(arg => !!arg)
    .join('/')
    .replace(/\/{2,}/g, '/');
  return joined.length < 4 ? `${joined}/` : joined;
};

export const parseNumber = value => {
  return Number(value.toString().replace(/,/g, '.'));
};

export const correctPhone = str => `+${str.replace(/\D/g, '')}`;

export const formatPhone = str => {
  const phoneStr = correctPhone(str);
  const isValid = isValidPhoneNumber(phoneStr);
  if (!isValid) {
    return str;
  }
  const phone = parsePhoneNumber(phoneStr);
  return phone.formatInternational();
};
