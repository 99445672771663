import {
  Discount as DiscountIcon,
  Favorite as FavoriteIcon,
  Home as HomeIcon,
} from 'components/icons';

const PINNED_DESKTOP_CATEGORIES = [
  { id: '-1', key: 'favorites', icon: FavoriteIcon, link: '/favorites' },
  { id: '-2', key: 'special', icon: DiscountIcon, link: '/special' },
];

const PINNED_MOBILE_CATEGORIES = [
  { id: '-1', key: 'main', icon: HomeIcon, link: '/' },
  { id: '-2', key: 'special', icon: DiscountIcon, link: '/special' },
  { id: '-3', key: 'favorites', icon: FavoriteIcon, link: '/favorites' },
];

export { PINNED_DESKTOP_CATEGORIES, PINNED_MOBILE_CATEGORIES };
