import { createContext, useReducer, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CartStatusModal } from 'components';
import { useDarkstoreContext } from 'contexts/Darkstore';
import message from 'utils/message';
import { closeModal, openModal } from 'utils/modals';
import { calculateSum, loadState, saveState, prepareState } from './helpers';
import useAvailability from './useAvailability';
import useCheckWeightOrder from './useCheckWeightOrder';

const CartContext = createContext({});

const initialState = loadState();

const reducer = (state, action) => {
  switch (action.type) {
    case 'PRODUCT_ADDED': {
      const { product } = action.payload;
      const products = [...state.products, { ...product, maxQty: product.qty, qty: 1 }];
      products.sort(product => (product.readOnly ? 1 : -1));
      saveState(products);
      return { ...state, products, sum: calculateSum(products) };
    }

    case 'PRODUCT_QTY_CHANGED': {
      const { productId, qty } = action.payload;
      let products;
      if (qty === 0) {
        products = state.products.filter(product => product.id !== productId);
      } else {
        products = [...state.products];
        const index = products.findIndex(product => product.id === productId);
        products[index].qty = qty;
      }
      // clear cart if only readonly products available
      if (products.every(product => product.readOnly)) {
        saveState([]);
        return { ...state, products: [], sum: 0 };
      }
      saveState(products);
      return { ...state, products, sum: calculateSum(products) };
    }

    case 'PRODUCT_REMOVED': {
      const { productId } = action.payload;
      const products = state.products.filter(product => product.id !== productId);
      // clear cart if only readonly products available
      if (products.every(product => product.readOnly)) {
        saveState([]);
        return { ...state, products: [], sum: 0 };
      }
      saveState(products);
      return { ...state, products, sum: calculateSum(products) };
    }

    case 'PRODUCTS_CHANGED': {
      const { products } = action.payload;
      saveState(products);
      return { ...state, products, sum: calculateSum(products) };
    }

    case 'PRODUCTS_CLEARED': {
      saveState([]);
      return { ...state, products: [], sum: 0 };
    }

    default:
      return state;
  }
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isCheckingWeight, setIsCheckingWeight] = useState(false);
  const [orderWeight, setIsMaxWeightReached] = useState(false);

  const { t } = useTranslation();
  const { darkstore } = useDarkstoreContext();

  const handleWeightCheckSuccess = data => {
    setIsMaxWeightReached(data);
    setIsCheckingWeight(false);
  };

  const { refetch: checkWeight, isLoading } = useCheckWeightOrder({
    cartProducts: state.products,
    onSuccess: handleWeightCheckSuccess,
  });

  useAvailability({
    cartProducts: state.products,
    onSuccess: data => {
      const { products, changed, sold } = data;
      if (changed.length > 0 || sold.length > 0) {
        dispatch({ type: 'MODAL_OPENED', payload: { changed, sold } });
        const { modalId } = openModal(
          <CartStatusModal
            sold={sold}
            oldSum={state.sum}
            sum={calculateSum(products)}
            onClose={() => {
              closeModal(modalId);
              dispatch({ type: 'PRODUCTS_CHANGED', payload: { products } });
            }}
          />,
        );
      } else {
        dispatch({ type: 'PRODUCTS_CHANGED', payload: { products } });
      }
    },
  });

  useEffect(() => {
    if (isCheckingWeight || isLoading) {
      checkWeight();
    }
  }, [isCheckingWeight, checkWeight, isLoading]);

  const addProduct = product => {
    dispatch({ type: 'PRODUCT_ADDED', payload: { product } });
    setIsCheckingWeight(true);
    message(t('catalog:cart.message'), { type: 'default', withIcon: true });
  };

  const setProductQty = ({ productId, qty }) => {
    dispatch({ type: 'PRODUCT_QTY_CHANGED', payload: { productId, qty } });
    setIsCheckingWeight(true);
  };

  const removeProduct = ({ productId }) => {
    dispatch({ type: 'PRODUCT_REMOVED', payload: { productId } });
    setIsCheckingWeight(true);
  };

  const clearProducts = () => {
    dispatch({ type: 'PRODUCTS_CLEARED' });
    setIsCheckingWeight(true);
  };

  return (
    <CartContext.Provider
      value={{
        ...prepareState(state, darkstore),
        addProduct,
        setProductQty,
        removeProduct,
        clearProducts,
        orderWeight,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
