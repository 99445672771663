import { useMutation, useQueryClient } from 'react-query';
import { useDarkstoreContext } from 'contexts/Darkstore';
import api from 'utils/api';

const useReviewProduct = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const { darkstoreId } = useDarkstoreContext();

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ productId, rate, comment }) => {
      return api.post('products/rating', {
        product_id: productId,
        rate,
        comment,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([`products/${variables.productSlug}`, { darkstoreId }]);
      onSuccess();
    },
  });

  return { reviewProduct: mutate, isLoading };
};

export default useReviewProduct;
