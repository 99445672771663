import React from 'react';
import { SvgIcon } from '../shared';

const Delete = props => {
  return (
    <SvgIcon {...props}>
      <path d="M16.667 1.333h4.667v2.667h-18.667v-2.667h4.667l1.333-1.333h6.667l1.333 1.333zM6.667 24c-1.467 0-2.667-1.2-2.667-2.667v-16h16v16c0 1.467-1.2 2.667-2.667 2.667h-10.667z"></path>
    </SvgIcon>
  );
};

export default Delete;
