import React from 'react';
import { SvgIcon } from '../shared';

const Discount = props => {
  return (
    <SvgIcon {...props}>
      <path d="M9.897 9.277c-0.307 0.307-0.679 0.46-1.117 0.46-0.449 0-0.832-0.153-1.149-0.46-0.307-0.317-0.46-0.695-0.46-1.133s0.153-0.816 0.46-1.133c0.317-0.317 0.701-0.476 1.149-0.476 0.427 0 0.799 0.159 1.117 0.476s0.476 0.695 0.476 1.133c0 0.438-0.159 0.816-0.476 1.133z"></path>
      <path d="M16.58 15.549c-0.307 0.307-0.684 0.46-1.133 0.46-0.438 0-0.816-0.153-1.133-0.46-0.306-0.317-0.46-0.695-0.46-1.133 0-0.449 0.153-0.826 0.46-1.133 0.318-0.307 0.695-0.46 1.133-0.46 0.449 0 0.826 0.159 1.133 0.476 0.317 0.307 0.476 0.679 0.476 1.117s-0.159 0.816-0.476 1.133z"></path>
      <path d="M12 0c6.628 0 12 5.373 12 12s-5.372 12-12 12c-6.627 0-12-5.373-12-12s5.373-12 12-12zM7.187 17.438c0.164 0.164 0.367 0.246 0.608 0.246 0.252 0 0.46-0.088 0.624-0.263l8.72-11.216c0.12-0.153 0.181-0.323 0.181-0.509 0-0.23-0.082-0.427-0.246-0.591s-0.367-0.246-0.608-0.246c-0.252 0-0.476 0.109-0.673 0.328l-8.638 11.101c-0.131 0.142-0.197 0.328-0.197 0.558s0.077 0.427 0.23 0.591zM7.122 10.968c0.504 0.296 1.056 0.443 1.659 0.443s1.149-0.148 1.642-0.443c0.493-0.307 0.887-0.706 1.182-1.199s0.443-1.035 0.443-1.626-0.148-1.133-0.443-1.626c-0.296-0.504-0.69-0.903-1.182-1.199-0.493-0.307-1.040-0.46-1.642-0.46s-1.155 0.153-1.659 0.46c-0.493 0.296-0.887 0.695-1.182 1.199-0.296 0.493-0.443 1.035-0.443 1.626s0.148 1.133 0.443 1.626c0.296 0.493 0.69 0.892 1.182 1.199zM13.805 17.241c0.503 0.296 1.051 0.443 1.642 0.443 0.602 0 1.149-0.148 1.642-0.443s0.887-0.69 1.182-1.182c0.306-0.493 0.46-1.040 0.46-1.642 0-0.613-0.153-1.166-0.46-1.659-0.296-0.493-0.695-0.881-1.199-1.166-0.493-0.296-1.035-0.443-1.626-0.443-0.602 0-1.149 0.148-1.642 0.443s-0.887 0.69-1.182 1.182c-0.296 0.493-0.443 1.040-0.443 1.642 0 0.591 0.148 1.138 0.443 1.642 0.296 0.493 0.69 0.887 1.182 1.182z"></path>
    </SvgIcon>
  );
};

export default Discount;
