import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, useLocation, Redirect, useParams } from 'react-router';
import { joinPaths } from 'utils/helpers';

const LocalizedSwitch = ({ children }) => {
  const { i18n } = useTranslation();

  const { lang } = useParams();
  const { pathname, search } = useLocation();

  if (!i18n.languages.includes(lang)) {
    return <Redirect to={joinPaths('/', i18n.language, pathname.replace(lang, ''), search)} />;
  }

  if (!pathname.includes(`/${lang}/`)) {
    return <Redirect to={joinPaths('/', i18n.language)} />;
  }

  document.documentElement.lang = lang;

  return <Switch>{children}</Switch>;
};

export default LocalizedSwitch;
