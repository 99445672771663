import { MODAL_EVENTS } from 'contexts/ModalsContext';

const createEvent = (type, detail) => {
  return new CustomEvent(type, { detail });
};

export const openModal = modalComponent => {
  const modalId = `modal-${new Date().getTime().toString()}`;
  const event = createEvent(MODAL_EVENTS.open, {
    modalId,
    modalComponent,
  });
  window.dispatchEvent(event);
  return { modalId };
};

export const closeModal = modalId => {
  const event = createEvent(MODAL_EVENTS.close, { modalId });
  window.dispatchEvent(event);
};
