import React from 'react';
import ContentLoader from 'react-content-loader';

const CardLoader = () => (
  <ContentLoader viewBox="0 0 50 80">
    <rect x="0" y="0" rx="1" ry="1" width="50" height="80" />
  </ContentLoader>
);

export default CardLoader;
