import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import config from 'config';
import { useDarkstoreContext } from 'contexts/Darkstore';
import { TextField } from '../TextField';
import pl from './pl.json';
import uk from './uk.json';

const PREFERRED_COUNTRIES = ['by', 'ru', 'ua', 'kz', 'pl'];

const PRIORITY = config.BUILD_TYPE === 'emart' ? { kz: 0, ru: 1 } : { ru: 0, kz: 1 };

const MASKS_BY_COUNTRY = {
  by: '375 (29) 123-45-67',
  ru: '7 (910) 123-45-67',
  ua: '380 (96) 123-45-67',
  kz: '7 (700) 123-45-67',
  pl: '48 (77) 123-45-67',
};

const LOCALIZATIONS_BY_LANGUAGE = {
  ru,
  uk,
  pl,
};

const useLocalization = () => {
  const {
    i18n: { language },
  } = useTranslation();
  return LOCALIZATIONS_BY_LANGUAGE[language];
};

const PhoneTextField = ({ disabled, label, value, error, onChange, onEnterKeyPress, ...rest }) => {
  const localization = useLocalization();

  const {
    darkstore: { country },
  } = useDarkstoreContext();

  const [countryCode, setCountryCode] = useState(country);

  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      error={error}
      placeholder={MASKS_BY_COUNTRY[countryCode]}
      onChange={(newValue, country) => {
        setCountryCode(country.countryCode);
        onChange(newValue);
      }}
      onEnterKeyPress={onEnterKeyPress}
      {...rest}
    >
      <ReactPhoneInput
        localization={localization}
        country={countryCode}
        preferredCountries={PREFERRED_COUNTRIES}
        priority={PRIORITY}
      />
    </TextField>
  );
};

export default PhoneTextField;
