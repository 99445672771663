import React, { useContext } from 'react';
import { AccordionContext } from '../Accordion';
import styles from './AccordionSummary.module.scss';

const AccordionSummary = ({ children }) => {
  const { isExpanded, toggle } = useContext(AccordionContext);

  if (typeof children === 'function') {
    return <div className={styles.root}>{children({ isExpanded, toggle })}</div>;
  }

  return (
    <div className={styles.root} onClick={toggle}>
      {children}
    </div>
  );
};

export default AccordionSummary;
