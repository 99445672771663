import React, { useState } from 'react';
import {
  Button,
  LoadingIndicator,
  Modal,
  ModalContent,
  ModalTitle,
  Switch,
} from 'components/shared';
import { useCustomerContext } from 'contexts/Customer';
import loyaltyCard from 'img/loyaltyCard.png';
import useEditLoyalty from './useEditLoyalty';
import styles from './LoyaltyProgramModal.module.scss';

const LoyaltyProgramModal = ({ onClose }) => {
  const { customer } = useCustomerContext();
  const [checked, setChecked] = useState([
    customer.loyalty.isParticipation,
    customer.loyalty.isResearch,
    customer.loyalty.isMessages,
    customer.loyalty.isGames,
  ]);
  const { isEditing, edit } = useEditLoyalty({ onSuccess: onClose });
  return (
    <Modal className={styles.root} isOpened>
      <ModalTitle className={styles.title}>Купилка & Bazar</ModalTitle>
      {isEditing ? (
        <ModalContent>
          <LoadingIndicator />
        </ModalContent>
      ) : (
        <ModalContent>
          <span className={styles.caption}>Супервыгодная программа лояльности</span>
          <img
            className={styles.image}
            src={loyaltyCard}
            width={250}
            height={167}
            alt="LoyaltyCard"
            loading="lazy"
          />
          <p className={styles.text}>
            Для клиентов BAZAR стали доступны все плюшки программы лояльности КУПИЛКА: накопление
            бонусов, участие в рекламных играх и специальные скидки. Присоединяйтесь к Купилке и
            получайте вот это вот всё!
          </p>
          <div className={styles.switch}>
            <Switch
              size="large"
              checked={checked[0]}
              onChange={e => {
                const newChecked = [...checked];
                newChecked[0] = e.target.checked;
                setChecked(newChecked);
              }}
            />
            <span>Регистрация в бонусной программе Купилка</span>
          </div>
          <div className={styles.switch}>
            <Switch
              size="large"
              checked={checked[1]}
              onChange={e => {
                const newChecked = [...checked];
                newChecked[1] = e.target.checked;
                setChecked(newChecked);
              }}
            />
            <span>
              Проведение маркетинговых исследований для предоставления дополнительных преимуществ в
              бонусной программе «Купилка» и повышения качества обслуживания
            </span>
          </div>
          <div className={styles.switch}>
            <Switch
              size="large"
              checked={checked[2]}
              onChange={e => {
                const newChecked = [...checked];
                newChecked[2] = e.target.checked;
                setChecked(newChecked);
              }}
            />
            <span>
              Предоставление персонализированной информации новостного и рекламного характера,
              посредством электронной почты, Viber, СМС, Push
            </span>
          </div>
          <div className={styles.switch}>
            <Switch
              size="large"
              checked={checked[3]}
              onChange={e => {
                const newChecked = [...checked];
                newChecked[3] = e.target.checked;
                setChecked(newChecked);
              }}
            />
            <span>Участие в рекламных играх</span>
          </div>
          <Button
            className={styles.button}
            size="large"
            onClick={() => {
              edit({
                loyalty: {
                  isParticipation: checked[0],
                  isResearch: checked[1],
                  isMessages: checked[2],
                  isGames: checked[3],
                },
              });
            }}
          >
            Продолжить
          </Button>
        </ModalContent>
      )}
    </Modal>
  );
};

export default LoyaltyProgramModal;
