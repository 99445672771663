const validateForm = values => {
  let errors = {};

  if (!values.street) {
    errors.street = { key: 'mixed.required' };
  }

  if (!values.building) {
    errors.building = { key: 'mixed.required' };
  }

  if (!values.flat) {
    errors.flat = { key: 'custom.flat.required' };
  } else if (!values.flat.match(/[\d+]|[-]/)) {
    errors.flat = { key: 'custom.flat.matches' };
  }

  // if (values.darkstoreId === 200 && !values.corps) {
  //   errors.corps = { key: 'custom.corps.required' };
  // }

  return errors;
};

export default validateForm;
