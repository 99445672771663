import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDarkstoreContext } from 'contexts/Darkstore';
import demandMini from 'img/darkstore/demandMini.png';
import weatherMini from 'img/darkstore/weatherMini.png';
import styles from './DarkstoreStatusPanel.module.scss';

const DarkstoreStatusPanel = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { pathname } = useLocation();

  const {
    darkstore: { status, delivery },
  } = useDarkstoreContext();

  if (!['/', '/categories'].some(url => pathname.replace(`/${language}`, '') === url)) {
    return null;
  }

  if (status === 2) {
    return (
      <div className={styles.root}>
        <img src={demandMini} alt="DemandMini" width={36} height={24} />
        <span className={styles.popperTitle}>
          {t('statuses:darkstore.increasedDemandTitle')} -{' '}
          <b>{t('catalog:cart.delivery', { count: delivery }).toLowerCase()}</b>
        </span>
      </div>
    );
  }

  if (status === 3) {
    return (
      <div className={styles.root}>
        <img src={weatherMini} alt="WemandMini" width={35} height={27} />
        <span className={styles.popperTitle}>
          {t('statuses:darkstore.badWeatherTitle')} -{' '}
          <b>{t('catalog:cart.delivery', { count: delivery }).toLowerCase()}</b>
        </span>
      </div>
    );
  }

  return null;
};

export default DarkstoreStatusPanel;
