import React from 'react';
import { SvgIcon } from '../shared';

const Pin = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12 0c-4.699 0-8.5 3.756-8.5 8.4 0 6.3 8.5 15.6 8.5 15.6s8.5-9.3 8.5-15.6c0-4.644-3.801-8.4-8.5-8.4zM12 11.4c-1.676 0-3.036-1.344-3.036-3s1.36-3 3.036-3c1.676 0 3.036 1.344 3.036 3s-1.36 3-3.036 3z"></path>
    </SvgIcon>
  );
};

export default Pin;
