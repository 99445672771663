import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AddressModal } from 'components/AddressModal';
import { Pin as PinIcon } from 'components/icons';
import { useAddressContext } from 'contexts/Address';
import { closeModal, openModal } from 'utils/modals';
import styles from './HeaderAddress.module.scss';

const HeaderAddress = ({ className }) => {
  const { t } = useTranslation('main', { keyPrefix: 'header.address' });

  const { address } = useAddressContext();

  return (
    <div
      className={clsx(styles.root, !!address && styles.selected, className)}
      onClick={() => {
        const { modalId } = openModal(<AddressModal onClose={() => closeModal(modalId)} />);
      }}
    >
      <PinIcon size={18} />
      {address
        ? `${address.street} ${address.building}${
            address.corps ? `, ${t('cp')}${address.corps}` : ''
          }${address.flat ? `, ${t('ft')}${address.flat}` : ''}`
        : t('placeholder')}
    </div>
  );
};

export default HeaderAddress;
