import React from 'react';
import { SvgIcon } from '../shared';

const Menu = React.forwardRef((props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <path d="M23 13h-22c-0.552 0-1-0.448-1-1s0.448-1 1-1h22c0.552 0 1 0.448 1 1s-0.448 1-1 1z"></path>
      <path d="M23 5.334h-22c-0.552 0-1-0.448-1-1s0.448-1 1-1h22c0.552 0 1 0.448 1 1s-0.448 1-1 1z"></path>
      <path d="M23 20.666h-22c-0.552 0-1-0.448-1-1s0.448-1 1-1h22c0.552 0 1 0.448 1 1s-0.448 1-1 1z"></path>
    </SvgIcon>
  );
});

export default Menu;
