import React from 'react';
import { SvgIcon } from '../shared';

const ArrowRight = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12 0l-2.115 2.115 8.37 8.385h-18.255v3h18.255l-8.37 8.385 2.115 2.115 12-12-12-12z"></path>
    </SvgIcon>
  );
};

export default ArrowRight;
