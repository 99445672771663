import React from 'react';
import { useTranslation } from 'react-i18next';

const LocalizedImage = ({ className, src, alt, width, height, loading }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const localizedSrc = src ? src[language] || src.ru || null : null;

  return (
    <img
      className={className}
      src={localizedSrc}
      alt={alt}
      width={width}
      height={height}
      loading={loading}
    />
  );
};

export default LocalizedImage;
