import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Delete as DeleteIcon,
  Market as MarketIcon,
  Rocket as RocketIcon,
  Umbrella as UmbrellaIcon,
} from 'components/icons';
import { Button, LazyImage, UpDownButton } from 'components/shared';
import { useCartContext } from 'contexts/Cart';
import { useDarkstoreContext } from 'contexts/Darkstore';
import cart from 'img/cart.svg';
import noProducts from 'img/noProducts.png';
import { joinPaths } from 'utils/helpers';
import styles from './Cart.module.scss';

const Cart = ({ onCheckout }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    darkstore: { status, delivery, minSum, currency },
  } = useDarkstoreContext();

  const { products, sum, deliveryPrice, setProductQty, clearProducts, orderWeight } =
    useCartContext();

  if (products.length > 0) {
    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <img src={cart} alt="CartIcon" loading="lazy" width={24} height={22} />
          <p>{t('catalog:cart.title')}</p>
          <DeleteIcon size={18} onClick={clearProducts} />
        </div>
        <div className={styles.content}>
          {products.map(product => (
            <div className={styles.product} key={product.id}>
              <div className={styles['product__image']}>
                <LazyImage src={product.img} alt={product.name} width={50} height={50} />
              </div>
              <div className={styles['product__info']}>
                <p className={styles['product__title']}>{product.name}</p>
                <p className={styles['product__weight']}>{product.weight}</p>
                <div className={styles['product__price']}>
                  {product.oldPrice && (
                    <p className={styles['product__price-old']}>
                      {product.oldPrice}
                      {currency}
                    </p>
                  )}
                  <p className={styles['product__price-current']}>
                    {product.currentPrice}
                    {currency}
                  </p>
                </div>
              </div>
              {!product.readOnly && (
                <UpDownButton
                  size="small"
                  value={product.qty}
                  maxValue={product.maxQty}
                  onClick={newQty => setProductQty({ productId: product.id, qty: newQty })}
                />
              )}
            </div>
          ))}

          <div className={styles.product}>
            <div className={styles['product__image']}>
              {[-1, 0].includes(status) && <RocketIcon className={styles.productIcon} />}
              {status === 2 && <MarketIcon className={styles.productIcon} />}
              {status === 3 && <UmbrellaIcon className={styles.productIcon} />}
            </div>

            <div className={styles['product__info']}>
              {[-1, 0].includes(status) && (
                <p className={styles['product__title']}>
                  {t('catalog:cart.deliveryFast')}
                  <br />
                  {t('catalog:cart.delivery', { count: delivery })}
                </p>
              )}
              {status === 2 && (
                <p className={styles['product__title']}>
                  {t('catalog:cart.increasedDemand')}
                  <br />
                  {t('catalog:cart.delivery', { count: delivery })}
                </p>
              )}
              {status === 3 && (
                <p className={styles['product__title']}>
                  {t('catalog:cart.badWeather')} :(
                  <br />
                  {t('catalog:cart.delivery', { count: delivery })}
                </p>
              )}
              {deliveryPrice === 0 && (
                <div className={styles['product__price']}>
                  <p className={styles['product__price-current']}>0{currency}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <p className={styles.sum}>
          {t('catalog:cart.sum')}:{' '}
          <span>
            {sum}
            {currency}
          </span>
        </p>
        <div className={styles.footer}>
          <Link to={joinPaths('/', language, 'checkout')}>
            <Button
              data-gtm-id="CheckoutButton"
              disabled={sum - deliveryPrice < minSum || orderWeight.isMaxWeightReached}
              onClick={() => onCheckout && onCheckout()}
            >
              {t('catalog:cart.checkout')}
            </Button>
          </Link>
          {sum - deliveryPrice < minSum && (
            <span className={styles.error}>
              {t('catalog:cart.minSum')} {minSum}
              {currency}
            </span>
          )}
          {orderWeight.isMaxWeightReached && (
            <span className={styles.error}>
              {t('catalog:cart.maxOrderWeight')} ({orderWeight.maxWeight}
              {t('catalog:cart.kg')}).
              <br />
              {t('catalog:cart.currentOrderWeight')} {orderWeight.weight}
              {t('catalog:cart.kg')}
            </span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img src={cart} alt="CartIcon" loading="lazy" width={24} height={22} />
        <p>{t('catalog:cart.title')}</p>
      </div>

      <div className={styles['no-products']}>
        <img src={noProducts} alt="NoProducts" loading="lazy" width={125} height={100} />
        <p className={styles['no-products__title']}>{t('catalog:cart.emptyTitle')} :(</p>
        <p className={styles['no-products__description']}>
          <Link
            to={joinPaths('/', language, 'categories')}
            onClick={() => onCheckout && onCheckout()}
          >
            {t('catalog:cart.makeCart')}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Cart;
