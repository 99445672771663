export const OPTIONS = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  styles: [
    {
      stylers: [
        {
          saturation: -100,
        },
      ],
    },
  ],
};
