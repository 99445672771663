import React from 'react';
import clsx from 'clsx';
import { Popper } from '../Popper';
import styles from './Menu.module.scss';

const Menu = ({ children, className, referenceElement, isOpened, onClickOutside }) => {
  return (
    <Popper
      className={clsx(styles.popper, className)}
      referenceElement={referenceElement}
      isOpened={isOpened}
      placement="bottom-end"
      onClickOutside={onClickOutside}
    >
      {children}
    </Popper>
  );
};

export default Menu;
