import React from 'react';
import { SvgIcon } from '../shared';

const Home = props => {
  return (
    <SvgIcon {...props}>
      <path d="M23.355 10.439c-0.001-0.001-0.001-0.001-0.002-0.002l-9.791-9.79c-0.417-0.417-0.972-0.647-1.562-0.647s-1.145 0.23-1.563 0.647l-9.786 9.785c-0.003 0.003-0.007 0.007-0.010 0.010-0.857 0.862-0.856 2.26 0.004 3.12 0.393 0.393 0.912 0.621 1.466 0.644 0.023 0.002 0.045 0.003 0.068 0.003h0.39v7.205c0 1.426 1.16 2.586 2.586 2.586h3.831c0.388 0 0.703-0.315 0.703-0.703v-5.649c0-0.651 0.529-1.18 1.18-1.18h2.259c0.651 0 1.18 0.529 1.18 1.18v5.649c0 0.388 0.315 0.703 0.703 0.703h3.831c1.426 0 2.586-1.16 2.586-2.586v-7.205h0.362c0.59 0 1.145-0.23 1.563-0.647 0.861-0.861 0.861-2.262 0.001-3.124z"></path>
    </SvgIcon>
  );
};

export default Home;
