import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalContent, ModalTitle, PhoneTextField } from 'components/shared';
import pineappleHappy from 'img/pineappleHappy.png';
import { validatePhone } from 'utils/helpers';
import useWait from './useWait';
import styles from './WaitingModal.module.scss';

const WaitingModal = ({ street, building, onClose }) => {
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState('');

  const { wait } = useWait({ onSuccess: onClose });

  return (
    <Modal isOpened onClose={onClose}>
      <ModalTitle onClose={onClose} />
      <ModalContent>
        <img className={styles.image} src={pineappleHappy} alt="PineappleHappy" />
        <p className={styles.title}>{t('address:waiting.title')}</p>
        <div className={styles.content}>
          <p>{t('address:waiting.text')}</p>
          <PhoneTextField
            label="Номер"
            value={phoneNumber}
            onChange={value => setPhoneNumber(value)}
          />
          <Button
            size="large"
            disabled={!validatePhone(phoneNumber)}
            onClick={() => wait({ address: `${street} ${building}`, phone: phoneNumber })}
          >
            {t('shared:buttons.seeYou')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default WaitingModal;
