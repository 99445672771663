import React from 'react';
import clsx from 'clsx';
import styles from './Radio.module.scss';

const Radio = ({ className, classes = {}, name, label, value, checked, readOnly, onChange }) => {
  return (
    <label className={clsx(styles.root, className, classes.root)}>
      <input
        className={clsx(styles.input, classes.input)}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        readOnly={readOnly}
        onChange={onChange}
      />
      <span className={clsx(styles.checkmark, classes.checkmark)} />
      <span className={clsx(styles.label, classes.label)}>{label}</span>
    </label>
  );
};

export default Radio;
