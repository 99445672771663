import { useQuery, useQueryClient } from 'react-query';
import { useDarkstoreContext } from 'contexts/Darkstore';
import api from 'utils/api';

const useCheckWeightOrder = ({ cartProducts, onSuccess }) => {
  const queryClient = useQueryClient();
  const { darkstoreId } = useDarkstoreContext();

  const query = useQuery({
    queryKey: ['cart-weight', { darkstoreId }],
    queryFn: async () => {
      const result = await api.post('cart', {
        darkstoreId,
        items: cartProducts.map(product => ({
          productId: product.id,
          qty: product.qty,
        })),
      });

      const isMaxWeightReached = result.weight > result.maxWeight ? true : false;

      return {
        weight: parseFloat(result.weight.toFixed(2)),
        maxWeight: result.maxWeight,
        isMaxWeightReached,
      };
    },
    enabled: cartProducts.length > 0,
    onSuccess,
  });

  const refetch = () => {
    queryClient.invalidateQueries(['cart-weight', { darkstoreId }]);
    query.refetch();
  };

  return { refetch, ...query };
};

export default useCheckWeightOrder;
