import React from 'react';
import clsx from 'clsx';
import { capitalize } from 'utils/helpers';
import styles from './Switch.module.scss';

const Switch = ({ className, classes = {}, size = 'small', name, label, checked, onChange }) => {
  return (
    <label className={clsx(styles.root, className, classes.root)}>
      <input
        className={clsx(styles.input, classes.input)}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span
        className={clsx(styles.slider, styles[`slider${capitalize(size)}`], classes.slider)}
        style={{
          padding: checked ? '0 24px 0 2px' : '0 2px 0 24px',
          textAlign: checked ? 'right' : 'left',
        }}
      >
        {size === 'large' ? <>{checked ? 'Да' : 'Нет'}</> : null}
      </span>
    </label>
  );
};

export default Switch;
