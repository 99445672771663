import React from 'react';
import Img from 'react-cool-img';
import placeholder from 'img/placeholder.png';

const LazyImage = ({ className, src, alt, width, height, onClick }) => {
  return (
    <Img
      className={className}
      src={src}
      alt={alt}
      width={width}
      height={height}
      placeholder={placeholder}
      onClick={onClick}
    />
  );
};

export default LazyImage;
