import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDarkstoreContext } from 'contexts/Darkstore';
import api from 'utils/api';
import message from 'utils/message';

const setWait = ({ productId, isWait, darkstoreId }) => {
  if (isWait) {
    return api.delete('products/wait', { productId, darkstoreId });
  }
  return api.post('products/wait', { productId, darkstoreId });
};

const useSetWait = ({ productId, isWait, queryKey }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { darkstoreId } = useDarkstoreContext();

  const { mutate } = useMutation(() => setWait({ productId, isWait, darkstoreId }), {
    onSuccess: () => {
      if (!isWait) {
        message(t('product:addedToWait'), { type: 'default' });
      }

      if (['catalog/shopping-lists', 'catalog/favorites'].includes(queryKey)) {
        queryClient.invalidateQueries(queryKey);
      } else {
        queryClient.invalidateQueries([queryKey, { darkstoreId }]);
      }

      queryClient.invalidateQueries(`products/${productId}`);
    },
  });

  return {
    setWait: mutate,
  };
};

export default useSetWait;
