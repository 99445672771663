import React, { useEffect, useReducer } from 'react';
import { AddressModal } from 'components';
import { useCanDisplayGlobalModal } from 'hooks';
import { closeModal, openModal } from 'utils/modals';
import { loadState, saveState } from './helpers';

const AddressContext = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADDRESS_CHANGED': {
      saveState(action.payload);
      return { ...state, address: action.payload };
    }
    default:
      return state;
  }
};

const initialState = loadState();

const AddressProvider = ({ children }) => {
  const canDisplayGlobalModals = useCanDisplayGlobalModal();

  const [state, dispatch] = useReducer(reducer, initialState);

  const setAddress = address => dispatch({ type: 'ADDRESS_CHANGED', payload: address });

  useEffect(() => {
    const isNewbieGuideViewed = localStorage.getItem('isNewbieGuideViewed');
    const isAddressAsked = sessionStorage.getItem('isAddressAsked');
    if (canDisplayGlobalModals && !state.address && !isAddressAsked && isNewbieGuideViewed) {
      setTimeout(() => {
        const { modalId } = openModal(<AddressModal onClose={() => closeModal(modalId)} />);
      }, 100);
    }
  }, [canDisplayGlobalModals, state.address]);

  return (
    <AddressContext.Provider value={{ ...state, setAddress }}>{children}</AddressContext.Provider>
  );
};

export { AddressContext, AddressProvider };
