import { getImageUrl, parseNumber } from './helpers';

export const mapProducts = products => products.map(product => mapProduct(product));

export const getUnit = liter => {
  const language = localStorage.getItem('i18nextLng');
  if (liter) {
    return language === 'pl' ? 'l' : 'л';
  }
  return language === 'pl' ? 'kg' : 'кг';
};

const getWeightString = (weight, liter) => {
  const parsed = parseNumber(weight);
  const language = localStorage.getItem('i18nextLng');
  if (parsed < 1) {
    if (language === 'pl') {
      return `${(parsed * 1000).toFixed()}${liter ? 'ml' : 'g'}`;
    }
    return `${(parsed * 1000).toFixed()}${liter ? 'мл' : 'г'}`;
  }
  if (language === 'pl') {
    return `${parsed}${liter ? 'l' : 'kg'}`;
  }
  return `${parsed}${liter ? 'л' : 'кг'}`;
};

export const mapProduct = product => {
  const {
    id,
    product_id,
    productId,
    img,
    weight,
    liter,
    rating,
    id_fav,
    idFav,
    discount,
    priceDiscount,
    price,
    cod,
    document,
    isReview,
    isWait,
    qty,
    ...other
  } = product;

  return {
    id: productId || product_id || id,
    img: getImageUrl(img),
    weight: weight ? `${getWeightString(weight, liter)}` : '',
    rating: rating && rating > 0 ? rating : null,
    isFavorite: !!id_fav || !!idFav,
    discount: discount > 0 ? discount : '',
    oldPrice: priceDiscount && priceDiscount < price ? price : '',
    currentPrice: priceDiscount && priceDiscount < price ? priceDiscount : price,
    barcode: cod,
    regulatoryDocument: document,
    isReviewed: isReview,
    isWait: !!isWait,
    qty: qty || 0,
    ...other,
  };
};

//TODO: add mappers for orders/order
