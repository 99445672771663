import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useCustomerContext } from 'contexts/Customer';
import api from 'utils/api';
import message from 'utils/message';

const deletePaymentCard = paymentCardToken => api.delete(`customers/cards/${paymentCardToken}`);

const useDeletePaymentCard = ({ onSuccess }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { setPaymentCard } = useCustomerContext();

  const { mutate, isLoading } = useMutation(deletePaymentCard, {
    onSuccess: (_, paymentCardToken) => {
      queryClient.setQueryData('account', account => {
        const { cards } = account;
        return {
          ...account,
          cards: cards.filter(card => card.token !== paymentCardToken),
        };
      });

      const { cards } = queryClient.getQueryData('account');
      if (cards && cards.length > 0) {
        setPaymentCard(cards[0].token);
      }

      message(t('account:infoSaveMessage'), { type: 'success' });
      onSuccess();
    },
    onError: error => message(error.message, { type: 'error' }),
  });

  return { deletePaymentCard: mutate, isDeleting: isLoading };
};

export default useDeletePaymentCard;
