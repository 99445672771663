import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import styles from './Modal.module.scss';

const Modal = ({
  children = null,
  className = null,
  classes = {},
  width = 420,
  isFullScreen = false,
  isOpened = false,
  onClose = () => {},
}) => {
  const overlayRef = useRef();

  if (isOpened) {
    return ReactDOM.createPortal(
      <div
        ref={overlayRef}
        className={clsx(styles.overlay, isFullScreen && styles.overlayFullScreen, classes.overlay)}
        onClick={e => {
          if (e.target === overlayRef.current) {
            onClose();
          }
        }}
      >
        <div
          style={{ width }}
          className={clsx(
            styles.root,
            isFullScreen && styles.rootFullScreen,
            className,
            classes.root,
          )}
        >
          {children}
        </div>
      </div>,
      document.body,
    );
  }

  return null;
};

export default Modal;
