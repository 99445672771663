import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'hooks';
import { useCartContext } from 'contexts/Cart';
import { useProduct } from 'hooks/data';
import { joinPaths } from 'utils/helpers';
import { closeModal, openModal } from 'utils/modals';
import { ArrowRight as ArrowRightIcon } from '../icons';
import { FavoriteButton, ProductReviewModal, Reviews, WaitButton } from '../index';
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
  Button,
  UpDownButton,
  LoadingIndicator,
  LazyImage,
} from '../shared';
import styles from './ProductModal.module.scss';

const ProductModal = ({ isAuthenticated, productSlug, queryKey, onClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { products: cartProducts, addProduct, setProductQty } = useCartContext();

  const { isLoading, product } = useProduct(productSlug);

  const isFullScreen = useMediaQuery('screen and (max-width: 420px)');

  if (!productSlug) {
    return null;
  }

  const {
    id,
    name,
    slug,
    weight,
    img,
    barcode,
    country,
    brand,
    composition,
    oldPrice,
    currentPrice,
    currency,
    discount,
    qty,
    description,
    isFavorite,
    isWait,
    reviews,
  } = product;

  const inCart = cartProducts.find(cartProduct => cartProduct.id === id);

  return (
    <Modal isOpened isFullScreen={isFullScreen} width={680} onClose={onClose}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <ModalTitle
            classes={{ root: styles.title, closeIcon: styles.titleCloseIcon }}
            isTextContent={false}
            onClose={onClose}
          >
            <div className={styles.title}>
              <span className={styles.titleName}>{name}</span>
              {weight && <span className={styles.titleWeight}>{weight}</span>}
            </div>
          </ModalTitle>
          <ModalContent>
            <div className={styles.infoContainer}>
              <div className={styles.image}>
                <LazyImage src={img} alt={name} />
                {product.advIcon && (
                  <img className={styles.advIcon} src={product.advIcon} alt="Adv icon" />
                )}
              </div>
              <div className={styles.info}>
                {barcode && (
                  <p>
                    <b>{t('product:barcode')}:</b>
                    {barcode}
                  </p>
                )}
                {country && (
                  <p>
                    <b>{t('product:country')}:</b>
                    {country}
                  </p>
                )}
                {brand && (
                  <p>
                    <b>{t('product:brand')}:</b>
                    {brand}
                  </p>
                )}
                {composition && (
                  <p>
                    <b>{t('product:composition')}:</b>
                    {composition}
                  </p>
                )}
                {!!oldPrice ? (
                  <div className={styles.price}>
                    <p className={styles.priceOld}>
                      {oldPrice}
                      {currency}
                    </p>
                    <p className={styles.priceCurrent}>
                      {currentPrice}
                      {currency}
                    </p>
                    <span className={styles.priceDiscount}>-{discount}%</span>
                  </div>
                ) : (
                  <div className={styles.price}>
                    <b>{t('product:price')}:</b>
                    <p className={styles.priceCurrent}>
                      {currentPrice}
                      {currency}
                    </p>
                  </div>
                )}
                <div className={styles.buttons}>
                  {inCart ? (
                    <UpDownButton
                      value={inCart.qty}
                      maxValue={inCart.maxQty}
                      onClick={newQty => setProductQty({ productId: id, qty: newQty })}
                    />
                  ) : (
                    <Button
                      data-gtm-id="AddToCartButton"
                      size="small"
                      disabled={qty <= 0}
                      onClick={() => addProduct(product)}
                    >
                      {qty <= 0 ? t('product:zeroQty') : t('product:addToCart')}
                    </Button>
                  )}
                  <FavoriteButton isFavorite={isFavorite} productId={id} queryKey={queryKey} />
                  {qty <= 0 && <WaitButton isWait={isWait} productId={id} queryKey={queryKey} />}
                </div>
              </div>
            </div>
            {description && <p className={styles.description}>{description}</p>}
            {reviews.length > 0 && (
              <Reviews
                reviews={reviews}
                onAddClick={
                  isAuthenticated
                    ? () => {
                        const { modalId } = openModal(
                          <ProductReviewModal
                            productId={product.id}
                            productSlug={product.slug}
                            productImg={product.img}
                            onClose={() => closeModal(modalId)}
                          />,
                        );
                      }
                    : null
                }
              />
            )}
          </ModalContent>
          <ModalActions className={styles.actions}>
            <Link to={joinPaths('/', language, 'products', slug)}>
              <Button>
                {t('product:toProductPage')}
                <ArrowRightIcon color="white" size={16} />
              </Button>
            </Link>
          </ModalActions>
        </>
      )}
    </Modal>
  );
};

export default ProductModal;
