import config from 'config';

// setup primary color depends on build type
const SASS_THEMES = {
  sosedi: {
    'color-primary': '#e95d1d',
    'color-primary--hover': '#eb6d33',
    'color-primary--active': '#d1531a',
    'color-primary-filter':
      'invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)',
  },
  emart: {
    'color-primary': '#f06135',
    'color-primary--hover': '#e5582d',
    'color-primary--active': '#da4e24',
    'color-primary-filter':
      'invert(67%) sepia(59%) saturate(6015%) hue-rotate(339deg) brightness(96%) contrast(96%)',
  },
};

const theme = SASS_THEMES[config.BUILD_TYPE];

Object.entries(theme).forEach(([key, value]) => {
  document.documentElement.style.setProperty(`--${key}`, value);
});

// fix viewport on mobile devices https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
document.documentElement.style.setProperty('--fvh', `${window.innerHeight}px`);
window.addEventListener('resize', () =>
  document.documentElement.style.setProperty('--fvh', `${window.innerHeight}px`),
);
