export const loadState = () => {
  try {
    const serialized = localStorage.getItem('address');

    if (serialized) {
      const parsed = JSON.parse(serialized);

      if (typeof parsed === 'object') {
        return {
          address: parsed,
          isModalOpened: false,
        };
      }
    }

    return {
      address: null,
      isModalOpened: false,
    };
  } catch (err) {
    localStorage.removeItem('address');

    return {
      address: null,
      isModalOpened: false,
    };
  }
};

export const saveState = address => {
  try {
    const serialized = JSON.stringify(address);
    localStorage.setItem('address', serialized);
  } catch (err) {}
};
