import React from 'react';
import { Star, StarOutline } from 'components/icons';
import styles from './Rating.module.scss';

const Rating = ({ value = 0, color = 'primary', size = 20, hideEmpty = false, onChange }) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (hideEmpty && i === value) {
      break;
    }
    if (value <= i) {
      stars.push(
        <StarOutline
          key={i}
          color={color}
          size={size}
          style={{ marginRight: size / 4 }}
          onClick={onChange ? () => onChange(i + 1) : null}
        />,
      );
    } else {
      stars.push(
        <Star
          key={i}
          color={color}
          size={size}
          style={{ marginRight: size / 4 }}
          onClick={onChange ? () => onChange(i + 1) : null}
        />,
      );
    }
  }
  return <div className={styles.root}>{stars}</div>;
};

export default Rating;
