import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { PINNED_MOBILE_CATEGORIES } from 'constants/categories';
import { joinPaths } from 'utils/helpers';
import styles from './MobileMenu.module.scss';

const MobileMenu = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { pathname } = useLocation();

  if (pathname.includes('mobile-app')) {
    return null;
  }

  return (
    <div className={styles.root}>
      <ul className={styles.list}>
        {PINNED_MOBILE_CATEGORIES.map((category, index) => (
          <li
            key={`${category.id}-${index}`}
            className={clsx(
              styles.listItem,
              pathname.replace(`/${language}`, '') === category.link && styles.listItemActive,
            )}
          >
            <Link to={joinPaths('/', language, category.link)}>
              <category.icon size={20} />
              <p>{t(`catalog:mobileCategories.${category.key}`)}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileMenu;
