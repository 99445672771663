import React from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import config from 'config';
import { useOnMount, useSetState } from 'hooks';
import { Button, Checkbox } from 'components/shared';
import { useDarkstoreContext } from 'contexts/Darkstore';
import cookies from 'img/cookies.png';
import { joinPaths } from 'utils/helpers';
import styles from './CookiesPolicy.module.scss';

const initGTM = gtmIds => {
  if (config.NODE_ENV === 'production' && config.SERVER === 'production') {
    if (gtmIds.length > 0) {
      gtmIds.forEach(gtmId => TagManager.initialize({ gtmId }));
    }
  } else {
    console.log(`DEV_LOG: gtm not started in DEV mode, current gtmIds = ${JSON.stringify(gtmIds)}`);
  }
};

const CookiesPolicy = () => {
  const { t, language } = useTranslation();

  const {
    gtmIds,
    darkstore: { country },
  } = useDarkstoreContext();

  const [state, setState] = useSetState({
    mode: 'none',
    acceptedCookies: ['technical'],
  });

  useOnMount(() => {
    if (country === 'pl') {
      const acceptedCookies = JSON.parse(localStorage.getItem('acceptedCookies'));
      if (!acceptedCookies) {
        return setState({ mode: 'notify' });
      }
      if (!acceptedCookies.includes('marketing')) {
        return;
      }
    }
    initGTM(gtmIds);
  });

  const handleAcceptCookies = acceptedCookies => {
    localStorage.setItem('acceptedCookies', JSON.stringify(acceptedCookies));
    if (acceptedCookies.includes('marketing')) {
      initGTM(gtmIds);
    }
    setState({ mode: 'accepted', acceptedCookies: ['technical'] });
  };

  if (state.mode === 'notify') {
    return (
      <div className={styles.root}>
        <div className={styles.description}>
          <img
            className={styles.descriptionImage}
            src={cookies}
            alt="Cookies"
            width={55}
            height={57}
          />
          <p className={styles.descriptionText}>
            <b>{t('main:cookiesPolicy.description.0')}</b>
            {t('main:cookiesPolicy.description.1')}
            <Link to={joinPaths('/', language, 'legal/cookies')}>
              {t('main:cookiesPolicy.description.2')}
            </Link>
            .
          </p>
        </div>
        <div className={styles.footer}>
          <Button
            type="button"
            color="primary"
            onClick={() => handleAcceptCookies(['technical', 'marketing', 'other'])}
          >
            {t('main:cookiesPolicy.accept')}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="text"
            onClick={() => setState({ mode: 'setup' })}
          >
            {t('main:cookiesPolicy.setup')}
          </Button>
        </div>
      </div>
    );
  }

  if (state.mode === 'setup') {
    return (
      <div className={styles.root}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Checkbox disabled checked />
            <p>
              <b> {t('main:cookiesPolicy.technical.0')}</b>
              {t('main:cookiesPolicy.technical.1')}
            </p>
          </li>
          <li className={styles.listItem}>
            <Checkbox
              checked={state.acceptedCookies.includes('marketing')}
              onChange={() =>
                setState(prevState => {
                  if (prevState.acceptedCookies.includes('marketing')) {
                    return {
                      acceptedCookies: prevState.acceptedCookies.filter(
                        cookie => cookie !== 'marketing',
                      ),
                    };
                  }
                  return { acceptedCookies: [...prevState.acceptedCookies, 'marketing'] };
                })
              }
            />
            <p>
              <b> {t('main:cookiesPolicy.marketing.0')}</b>
              {t('main:cookiesPolicy.marketing.1')}
            </p>
          </li>
          <li className={styles.listItem}>
            <Checkbox
              checked={state.acceptedCookies.includes('other')}
              onChange={() =>
                setState(prevState => {
                  if (prevState.acceptedCookies.includes('other')) {
                    return {
                      acceptedCookies: prevState.acceptedCookies.filter(
                        cookie => cookie !== 'other',
                      ),
                    };
                  }
                  return { acceptedCookies: [...prevState.acceptedCookies, 'other'] };
                })
              }
            />
            <p>
              <b> {t('main:cookiesPolicy.other.0')}</b>
              {t('main:cookiesPolicy.other.1')}
            </p>
          </li>
        </ul>
        <p className={styles.more}>
          {t('main:cookiesPolicy.more.0')}
          <Link to={joinPaths('/', language, 'legal')}>{t('main:cookiesPolicy.more.1')}</Link>.
        </p>
        <div className={styles.footer}>
          <Button
            type="button"
            color="primary"
            onClick={() => handleAcceptCookies(['technical', 'marketing', 'other'])}
          >
            {t('main:cookiesPolicy.acceptAll')}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="text"
            onClick={() => handleAcceptCookies(state.acceptedCookies)}
          >
            {t('main:cookiesPolicy.acceptSelected')}
          </Button>
        </div>
      </div>
    );
  }

  return null;
};

export default CookiesPolicy;
