import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import { useClickOutside } from 'hooks';
import styles from './Popper.module.scss';

const Popper = ({
  children = null,
  className = null,
  classes = {},
  referenceElement = null,
  isOpened = false,
  withArrow = false,
  autoWidth = false,
  placement = 'bottom',
  offset = [0, 8],
  onClickOutside,
}) => {
  const [popperElement, setPopperElement] = useState();
  const [arrowElement, setArrowElement] = useState(null);

  useClickOutside({
    enabled: isOpened,
    targets: [referenceElement, popperElement],
    onClickOutside,
  });

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        { name: 'offset', options: { offset } },
        { name: 'arrow', options: { element: arrowElement } },
      ],
    },
  );

  const rootStyles = {
    ...popperStyles.popper,
    ...(autoWidth && referenceElement
      ? { width: referenceElement.getBoundingClientRect().width }
      : {}),
  };

  if (!isOpened) {
    return null;
  }

  return (
    <div
      ref={setPopperElement}
      className={clsx(styles.root, className, classes.root)}
      style={rootStyles}
      {...popperAttributes.popper}
    >
      {withArrow && (
        <div
          ref={setArrowElement}
          style={popperStyles.arrow}
          className={clsx(styles.arrow, classes.arrow)}
        />
      )}
      {children}
    </div>
  );
};

export default Popper;
