import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Button,
  TextField,
  LoadingIndicator,
  Modal,
  ModalTitle,
  ModalContent,
} from 'components/shared';
import { Rating } from '../Rating';
import useReviewProduct from './useReviewProduct';
import styles from './ProductReviewModal.module.scss';

const ProductReviewModal = ({ productId, productSlug, productImg, onClose }) => {
  const { t } = useTranslation();

  const { reviewProduct, isLoading } = useReviewProduct({ onSuccess: onClose });

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      productId,
      productSlug,
      rate: 5,
      comment: '',
    },
    onSubmit: reviewProduct,
  });

  const { rate, comment } = values;

  return (
    <Modal isOpened onClose={onClose}>
      <ModalTitle onClose={onClose} />
      <ModalContent>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <form className={styles.form} onSubmit={handleSubmit}>
            <img src={productImg} alt="ProductImage" />
            <div className={styles.rating}>
              <Rating size={36} value={rate} onChange={value => setFieldValue('rate', value)} />
            </div>
            <TextField
              className={styles.textField}
              variant="default"
              multiline={true}
              rows="4"
              name="comment"
              label={t('shared:labels.comment')}
              placeholder={t('product:reviewPlaceholder')}
              value={comment}
              onChange={handleChange}
            />
            <Button size="large" type="submit">
              {t('product:addReview')}
            </Button>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ProductReviewModal;
