import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/shared';
import styles from './AddressForm.module.scss';

const AddressForm = ({ values, errors, handleChange, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <form className={styles.root} onSubmit={handleSubmit}>
      <>
        <div className={styles.fields}>
          <div className={styles.side}>
            <TextField
              disabled
              label={t('address:form.address')}
              value={`${values.street} ${values.building}`}
              onChange={handleChange}
            />
          </div>
          <div className={styles.side}>
            <TextField
              name="corps"
              label={t('address:form.corps')}
              value={values.corps}
              onChange={handleChange}
            />
            <TextField
              name="flat"
              label={t('address:form.flat')}
              value={values.flat}
              onChange={handleChange}
            />
            <TextField
              name="entrance"
              label={t('address:form.entrance')}
              value={values.entrance}
              onChange={handleChange}
            />
            <TextField
              name="floor"
              label={t('address:form.floor')}
              value={values.floor}
              onChange={handleChange}
            />
            <TextField
              name="intercom"
              label={t('address:form.intercom')}
              value={values.intercom}
              onChange={handleChange}
            />
          </div>
        </div>
      </>
      {errors.flat && <span className={styles.error}>{t(`validation:${errors.flat.key}`)}</span>}
      {errors.corps && <span className={styles.error}>{t(`validation:${errors.corps.key}`)}</span>}
    </form>
  );
};

export default AddressForm;
