import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ArrowUp as ArrowUpIcon } from 'components/icons';
import { LocalizedImage } from 'components/shared';
import mobileAppsLinks from 'constants/mobileAppsLinks';
import { useDarkstoreContext } from 'contexts/Darkstore';
import appStore from 'img/appStore.svg';
import appStorePL from 'img/appStorePL.svg';
import appStoreUK from 'img/appStoreUK.svg';
import googlePlay from 'img/googlePlay.svg';
import googlePlayPL from 'img/googlePlayPL.svg';
import googlePlayUK from 'img/googlePlayUK.svg';
import applePay from 'img/payment/applePayFooter.png';
import googlePay from 'img/payment/googlePayFooter.png';
import mastercard from 'img/payment/mastercardFooter.png';
import mastercardSecure from 'img/payment/mastercardSecureFooter.png';
import methods1 from 'img/payment/methods1.png';
import methods2 from 'img/payment/methods2.png';
import visa from 'img/payment/visaFooter.png';
import visaSecure from 'img/payment/visaSecureFooter.png';
import phone from 'img/phone.svg';
import fbIcon from 'img/social/fb.svg';
import instIcon from 'img/social/inst.svg';
import okIcon from 'img/social/ok.svg';
import vkIcon from 'img/social/vk.svg';
import ytbIcon from 'img/social/ytb.svg';
import { generateUrl } from 'lib/onelink';
import { correctPhone, formatPhone, joinPaths } from 'utils/helpers';
import styles from './Footer.module.scss';

const oneLinkUrl = generateUrl();

const Footer = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { pathname } = useLocation();

  const {
    darkstore: { country, facebook, youtube, vk, ok, instagram, schedule, tel, email, legal },
  } = useDarkstoreContext();

  return (
    <footer className={styles.root}>
      <div className={styles.top}>
        <div className={styles.content}>
          <span
            className={styles.scrollTop}
            onClick={() => {
              document.body.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <ArrowUpIcon color="text" />
            {t('main:footer.up')}
          </span>
        </div>
      </div>
      <div className={styles.content}>
        {!pathname.includes('mobile-app') && (
          <nav>
            <p className={styles.title}>{t('main:footer.customer')}</p>
            {country !== 'pl' && (
              <Link to={joinPaths('/', language)}>{t('main:footer.links.0')}</Link>
            )}
            <Link to={joinPaths('/', language, 'account/info')}>{t('main:footer.links.1')}</Link>
            <Link to={joinPaths('/', language, 'legal')}>{t('main:footer.links.4')}</Link>
            {country === 'pl' && (
              <Link to={joinPaths('/', language, 'legal/terms')}>{t('main:footer.links.5')}</Link>
            )}
          </nav>
        )}
        <div className={styles.links}>
          <p className={styles.title}>{t('main:footer.social')}</p>
          {facebook && (
            <a href={facebook} target="_blank" rel="noreferrer nofollow">
              <img src={fbIcon} alt="FacebookIcon" loading="lazy" width={26} height={26} />
            </a>
          )}
          {youtube && (
            <a href={youtube} target="_blank" rel="noreferrer nofollow">
              <img src={ytbIcon} alt="YoutubeIcon" loading="lazy" width={26} height={26} />
            </a>
          )}
          {country !== 'ua' && (
            <>
              {vk && (
                <a href={vk} target="_blank" rel="noreferrer nofollow">
                  <img src={vkIcon} alt="VkIcon" loading="lazy" width={26} height={26} />
                </a>
              )}
              {ok && (
                <a href={ok} target="_blank" rel="noreferrer nofollow">
                  <img src={okIcon} alt="OkIcon" loading="lazy" width={26} height={26} />
                </a>
              )}
            </>
          )}
          {instagram && (
            <a href={instagram} target="_blank" rel="noreferrer nofollow">
              <img src={instIcon} alt="InstagramIcon" loading="lazy" width={26} height={26} />
            </a>
          )}
        </div>
        {pathname.includes('mobile-app') ? (
          <div className={styles.links}>
            <p className={styles.title}>{t('main:footer.app')}</p>
            <a href={oneLinkUrl} target="_blank" rel="noreferrer nofollow">
              <img src={appStore} alt="AppStoreIcon" loading="lazy" width={162} height={43} />
            </a>
            <a href={oneLinkUrl} target="_blank" rel="noreferrer nofollow">
              <img src={googlePlay} alt="GooglePlayIcon" loading="lazy" width={162} height={43} />
            </a>
          </div>
        ) : (
          <div className={styles.links}>
            <p className={styles.title}>{t('main:footer.app')}</p>
            <a href={mobileAppsLinks.ios} target="_blank" rel="noreferrer nofollow">
              <LocalizedImage
                src={{
                  ru: appStore,
                  uk: appStoreUK,
                  pl: appStorePL,
                }}
                alt="AppStore"
                width={162}
                height={43}
                loading="lazy"
              />
            </a>
            <a href={mobileAppsLinks.android} target="_blank" rel="noreferrer nofollow">
              <LocalizedImage
                src={{
                  ru: googlePlay,
                  uk: googlePlayUK,
                  pl: googlePlayPL,
                }}
                alt="GooglePlay"
                width={162}
                height={43}
                loading="lazy"
              />
            </a>
          </div>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.content}>
        <div className={styles.contacts}>
          <p>
            {t('main:footer.weekdays')}: {schedule[1].start} - {schedule[1].finish},{' '}
            {t('main:footer.weekends')}: {schedule[6].start} - {schedule[6].finish}
          </p>
          <div className={styles.phones}>
            {tel.map(({ tel, icon }, index) => (
              <a href={`tel:${correctPhone(tel)}`} key={`darkstore-tel-${index}`}>
                {icon ? (
                  <img src={icon} alt="OperatorIcon" loading="lazy" width={30} height={30} />
                ) : (
                  <img src={phone} alt="PhoneIcon" loading="lazy" width={24} height={24} />
                )}
                {formatPhone(tel)}
              </a>
            ))}
          </div>
          {email && <a href={`mailto:${email}`}>{email}</a>}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.copyright}>{legal}</div>
        <div className={styles.paymentMethods}>
          {['ua', 'kz', 'pl'].includes(country) && (
            <>
              <img src={visa} alt="Visa" loading="lazy" width={54} height={46} />
              <a href="https://www.visa.com.ua/uk_UA/pay-with-visa/featured-technologies/visa-secure.html">
                <img src={visaSecure} alt="Visa Secure" loading="lazy" width={53} height={46} />
              </a>
              <a href="https://www.mastercard.ua/uk-ua/personal/get-support/safety-and-security/securecode.html">
                <img
                  src={mastercardSecure}
                  alt="PaymentMethods"
                  loading="Mastercard Secure Code"
                  width={51}
                  height={46}
                />
              </a>
              <img src={mastercard} alt="MasterCard" loading="lazy" width={56} height={46} />
            </>
          )}
          {['kz', 'pl'].includes(country) && (
            <>
              <img src={googlePay} alt="Google Pay" loading="lazy" width={54} height={46} />
              <img src={applePay} alt="Apple Pay" loading="lazy" width={54} height={46} />
            </>
          )}
          {['ru', 'by'].includes(country) && (
            <>
              <img src={methods1} alt="PaymentMethods1" loading="lazy" width={266} height={46} />
              <img src={methods2} alt="PaymentMethods2" loading="lazy" width={262} height={46} />
            </>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
