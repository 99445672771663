import React from 'react';
import { SvgIcon } from '../shared';

const FavoriteOutline = props => {
  return (
    <SvgIcon {...props}>
      <path d="M17.4 0.99c-2.088 0-4.092 0.972-5.4 2.508-1.308-1.536-3.312-2.508-5.4-2.508-3.696 0-6.6 2.904-6.6 6.6 0 4.536 4.080 8.232 10.26 13.848l1.74 1.572 1.74-1.584c6.18-5.604 10.26-9.3 10.26-13.836 0-3.696-2.904-6.6-6.6-6.6zM12.12 19.65l-0.12 0.12-0.12-0.12c-5.712-5.172-9.48-8.592-9.48-12.060 0-2.4 1.8-4.2 4.2-4.2 1.848 0 3.648 1.188 4.284 2.832h2.244c0.624-1.644 2.424-2.832 4.272-2.832 2.4 0 4.2 1.8 4.2 4.2 0 3.468-3.768 6.888-9.48 12.060z"></path>
    </SvgIcon>
  );
};

export default FavoriteOutline;
